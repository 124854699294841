import { Grid, Typography } from '@mui/material'
import PreviewCard from 'components/PreviewCard'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export default function EnrollmentsListItem({ data, campuses }) {
  const { t } = useTranslation()
  const { personId } = useParams()

  if (!campuses) return null

  return (
    <Grid container>
      <Grid item xs={12} md={12} sx={{ height: '100%', mb: 3 }}>
        <PreviewCard
          title={t('Campus Information')}
          size="large"
          buttonLabel={data?.id ? t('More') : t('Add')}
          buttonDisabled={data ? false : true}
          buttonNavigate={data ? `/person/${personId}/campus/${data?.id}` : `/person/${personId}/campus/new`}
        >
          <Grid container spacing={2}>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Campus')}</Typography>
              <Typography variant="subtitle1">
                {campuses?.find(camp => camp.id == data?.campusId)?.name}
                &nbsp;
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4} md={4}>
              <Typography variant="caption">{t('Start Date')}</Typography>
              <Typography variant="subtitle1">
                {data?.startDate && dayjs(data?.startDate).format('L')} &nbsp;
              </Typography>
            </Grid>
          </Grid>
        </PreviewCard>
      </Grid>
    </Grid>
  )
}
