import { Tooltip, TableCell } from '@mui/material';

const EllipsisTooltipCell = ({ text, ...props }) => {
  return (
    <TableCell
      {...props}
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 0,
        padding: 'inherit', 
      }}
    >
      <Tooltip title={text} arrow>
        <span
          style={{
            display: 'inline-block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            cursor: 'pointer',
          }}
        >
          {text}
        </span>
      </Tooltip>
    </TableCell>
  );
};

export default EllipsisTooltipCell;
