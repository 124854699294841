import { useEffect, Fragment } from 'react'
import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import SettingsIcon from '@mui/icons-material/Settings'
import HomeIcon from '@mui/icons-material/Home'
import PeopleIcon from '@mui/icons-material/People'
import SchoolIcon from '@mui/icons-material/School'
import { DescriptionIcon, LogoutIcon } from '../Icons'
import PermissionIcon from '@mui/icons-material/LocalPoliceOutlined'
import ScheduleIcon from '@mui/icons-material/CalendarMonth'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import { useSelector, useDispatch } from 'react-redux'
import { search } from 'store/academic/parent/parent-student'
import { hasPermission } from '../../store'
import { useSession } from 'components/Providers'
import { FaChalkboardTeacher, FaHeartbeat, FaSwatchbook, FaUserCheck, FaWalking } from 'react-icons/fa'

import { GroupsIcon, TrendingUpIcon } from 'components/Icons'
import ListAltIcon from '@mui/icons-material/ListAlt';
import { logout } from '../../store/session'
import { useNavigate } from 'react-router-dom'
import { FiAward } from 'react-icons/fi'
import ParentStudents from '../ParentStudents'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const providerMenu = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <HomeIcon />,
  },
  {
    title: 'Schools',
    path: '/schools',
    icon: <FiAward />,
  },
  {
    title: 'Employees',
    path: '/person',
    icon: <FaChalkboardTeacher />,
  },
  {
    title: 'Staff',
    path: '/member/staff',
    icon: <FaChalkboardTeacher />,
  },
  {
    title: 'Academic Year',
    path: '/settings/provider/academic-year',
    icon: <AutoStoriesIcon />
  },
  {
    title: 'Report',
    path: '/report',
    icon: <DescriptionIcon />,
  },
]
const schoolMenu = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <HomeIcon />,
  },
  {
    title: 'Staff',
    path: '/member/staff',
    icon: <PeopleIcon />,
    permission: 'staff.staff',
  },
  {
    title: 'Student',
    path: '/member/student',
    icon: <SchoolIcon />,
    permission: 'staff.staff',
  },
  {
    title: 'Atttendance',
    path: '/settings/attendance',
    icon: <FaUserCheck />,
    permission: 'setting.attendance',
  },
  {
    title: 'Leave Request',
    path: '/settings/leave-request',
    icon: <FaWalking />,
    //    permission: 'staff.staff',
  },
  {
    title: 'Scheduling',
    path: '/settings/scheduling',
    icon: <FaSwatchbook />,
    permission: 'setting.academic',
  },
  {
    title: 'Report',
    path: '/report',
    icon: <DescriptionIcon />,
    permission: 'staff.staff',
  },

  {
    title: 'Nurse',
    path: '/nurse',
    icon: <FaHeartbeat />,
    permission: 'staff.staff',
  },
  {
    title: 'Calendar',
    path: '/calendar',
    icon: <CalendarMonthIcon />,
    permission: 'staff.staff',
  },
  {
    title: 'Discipline',
    path: '/discipline',
    icon: <ListAltIcon />,
    permission: 'staff.staff',
  }
]
const academicMenu = [
  {
    title: 'Course Session',
    path: '/academic/course-session',
    icon: <GroupsIcon />,
    permission: session => Boolean(session.academicYear) && session.person?.type === 'staff',
  },
  {
    title: 'Final Grades',
    path: '/academic/grading-report',
    icon: <TrendingUpIcon />,
    permission: session => session.person?.type === 'student',
  },
  {
    title: 'Schedule',
    path: '/academic/schedule',
    icon: <ScheduleIcon />,
    permission: session => Boolean(session.academicYear),
  },
  {
    title: 'Attendance',
    path: '/academic/attendance',
    icon: <HowToRegIcon />,
    permission: session => Boolean(session.academicYear),
  },
  {
    title: 'Student',
    path: '/academic/student',
    icon: <SchoolIcon />,
    permission: session => Boolean(session.academicYear) && session.person.type === 'staff',
  },
]

function MenuItem({ page, location, isMenuCollapsed, sx, ...props }) {
  const { t } = useTranslation()

  return (
    <ListItem key={page} disablePadding {...props}>
      <ListItemButton sx={sx} component={Link} to={page.path} selected={location.pathname === page.path}>
        {isMenuCollapsed ? (
          <Tooltip title={t(page.title)} placement="right" arrow>
            <ListItemIcon>
              {page.icon}
            </ListItemIcon>
          </Tooltip>
        ) : (
      <>
      
      {page.icon && <ListItemIcon>{page.icon}</ListItemIcon>}
      <ListItemText inset={!page.icon} primary={t(page.title)} />
      </>
        )}
      </ListItemButton>
    </ListItem>
  )
}

MenuItem.propTypes = {
  page: PropTypes.object,
  location: PropTypes.object,
  sx: PropTypes.object,
}
export default function AppMenu({ isMenuCollapsed, menuCollapseToggle}) {
  const location = useLocation()
  const studentList = useSelector(state => state.academic.student.list)
  const dispatch = useDispatch()
  const { district, school, campus, academicYear, permissions, person } = useSession()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    if (!studentList && person?.type === 'parent') {
      dispatch(search());
    }
  }, [person?.type, studentList?.length]);

  const pages = district?.isServiceProvider ? providerMenu : schoolMenu
  const shouldShowAcademicMenu = !district?.isServiceProvider && ['staff', 'student'].includes(person?.type)

  const renderMenuItems = menuItems =>
    menuItems
      .filter(page => hasPermission(page.permission))
      .map(page => <MenuItem data-menu={page.title} key={page.path} page={page} location={location} isMenuCollapsed={isMenuCollapsed}  />)

  return (
    <Grid
      data-component="AppMenu"
      container
      direction="column"
      sx={{ height: '100vh', justifyContent: 'space-between', pl: isMenuCollapsed ? 0 : 1, pr: 1, overflow: 'hidden',}}
    >
      <Grid item className={`appMenu ${isMenuCollapsed ? 'collapsed' : ''}`}>
        <Box display="flex" flexDirection="column" sx={{height: '100vh'}}>
          <Box >
            <Toolbar sx={{ flexDirection: 'column', alignItems: 'center' }}>
            <IconButton 
              onClick={menuCollapseToggle}
              sx={{ position: 'absolute', 
                    top: 25,
                    left: isMenuCollapsed ? '45%' : 0,
                    display: { xs: 'none', sm: 'flex'}, 
                    transform: isMenuCollapsed ? 'translateX(-50%)' : 'none', 
                    backgroundColor: 'white', 
                    '&:hover': {
                      backgroundColor: 'primary.light',
                    },
                    borderRadius: '50%', 
                    boxShadow: 2, p: 0}}  
            >
                {isMenuCollapsed ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
              <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pt: 1}}>
                {!isMenuCollapsed && (
                <>
                  {campus?.file?.location ? (
                    <img onClick={() => {navigate('/')}} src={campus?.file?.location} alt="Logo" style={{ width: 80, cursor: 'pointer' }} />
                  ) : district?.file?.location ? (
                    <img onClick={() => {navigate('/')}} src={district?.file?.location} alt="Logo" style={{ width: 80, cursor: 'pointer' }} />
                  ) : (
                    <SchoolIcon
                      onClick={() => {
                        navigate('/')
                      }}
                      sx={{ fontSize: 100, cursor: 'pointer' }}
                    />
                  )}

              <Typography
                variant="h6"
                component="a"
                onClick={() => {
                  navigate('/')
                }}
                sx={{
                  pb: 1,
                  display: 'flex',
                  flexGrow: 1,
                  fontWeight: 500,
                  color: 'primary.contrastText',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                {campus?.name || school?.name || district?.name || ''} 
              </Typography>
              </>
              )}
              </Box>
            </Toolbar>
            <Divider color='white' />
            <Box sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 150px - 50px - 50px - 30px)' }}>
              <List >
                {renderMenuItems(pages)}
                {person?.type !== 'staff' && studentList && (
                  <ParentStudents studentList={studentList} location={location} MenuItem={MenuItem} />
                )}
              </List>
              {shouldShowAcademicMenu && (
                <Fragment>
                  <Divider color='white' />
                  <List>{renderMenuItems(academicMenu)}</List>
                </Fragment>
              )}
            </Box>
            <Divider color='white'/>
          </Box>
          <Box  style={{ marginTop: 'auto', overflow: 'auto',}}>
            <Grid item sx={{ paddingBottom: '40px' }}>
              {district?.isServiceProvider && (
                <ListItem data-menu="Settings" disablePadding>
                  <ListItemButton onClick={() => navigate('/setting')}>
                    {isMenuCollapsed ? (
                      <Tooltip title={t("Settings")} placement="right" arrow>
                        <ListItemIcon >
                          <SettingsIcon />
                        </ListItemIcon>
                      </Tooltip>
                    ) : (
                      <>                      
                        <ListItemIcon>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Settings")} />
                      </>
                  )}
                  </ListItemButton>
                </ListItem>
              )}

              {academicYear?.id && permissions?.['setting.setting'] && (
                <ListItem data-menu="Settings" disablePadding>
                  <ListItemButton onClick={() => navigate('/settings_')}>
                    {isMenuCollapsed ? (
                      <Tooltip title={t("Settings")} placement="right" arrow>
                      <ListItemIcon >
                        <SettingsIcon />
                      </ListItemIcon>
                      </Tooltip> 
                    ) : (
                      <>                 
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("Settings")}/>
                      </>
                    )}
                  </ListItemButton>
                </ListItem>
              )}

              {district?.isServiceProvider && (
                <ListItem data-menu="Permissions" disablePadding>
                  <ListItemButton onClick={() => navigate('/settings/permission')}>
                  {isMenuCollapsed ? (
                      <Tooltip title='Permissions' placement="right" arrow>
                        <ListItemIcon >
                          <PermissionIcon />
                        </ListItemIcon>
                      </Tooltip>
                  ) : (
                    <>     
                    <ListItemIcon>
                      <PermissionIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Permissions")} />
                    </>
                  )}
                  </ListItemButton>
                </ListItem>
              )}

              <ListItem data-menu="Logout" disablePadding>
                  <ListItemButton onClick={() => dispatch(logout())}>
                  {isMenuCollapsed ? (
                      <Tooltip title={t("Logout")} placement="right" arrow>
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                      </Tooltip>
                  ) : (
                    <>
                    
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                    <ListItemText primary={t("Logout")} />
                    </>
                  )}
                  </ListItemButton>
                </ListItem>

            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
