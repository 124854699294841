import { createTheme } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { red, } from '@mui/material/colors'
import _ from 'lodash'

const typography = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  h1: {
    fontSize: '96px',
    fontWeight: 'light',
    lineHeight: 1.2,
  },
  h2: {
    fontSize: '60px',
    fontWeight: 500,
    lineHeight: 1.3,
  },
  h3: {
    fontSize: '48px',
    fontWeight: 500,
    lineHeight: 1.3,
  },
  h4: {
    fontSize: '34px',
    fontWeight: 500,
    lineHeight: 1.3,
  },
  h5: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: 1.3,
  },
  h6: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: 1.3,
  },
  body1: {
    fontSize: '16px',
    lineHeight: 1.5,
  },
  body2: {
    fontSize: '14px',
    lineHeight: 1.43,
  },

  subtitle1: {
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '28px',
  },

  button: {
    fontWeight: 500,
  },
}

// Components override settings
const components = palette => ({
  MuiListItemButton: {
    styleOverrides: {
      root: {
        '.appMenu &': {
          borderRadius: '8px',
          marginBottom: '2px',
          color: 'white',
          '&.Mui-selected': {
            backgroundColor: palette.primary.light,
          },
          '&.Mui-selected:hover': {
            backgroundColor: palette.primary.light,
          },
          '&:hover': {
            backgroundColor: palette.primary.dark,
          },
        },
        '.appMenu.collapsed &': {
          borderRadius: '0px',
        },
      },
    },
  },

  MuiToolbar: {
    styleOverrides: {
      root: {
        '.appMenu &': {
          paddingLeft: '10px',
          paddingRight: '10px',
        },
      },
    },
  },

  MuiListItem: {
    styleOverrides: {
      root: {
        marginBottom: 0,
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        '.appMenu &': {
          color: 'white',
          fontSize: '24px',
          alignItems: 'center',
        },
        '.appMenu.collapsed &': {
          borderRadius: '0px',
          minWidth: 'auto',
          padding: '2px 4px 2px 4px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center' 
        },
      },
    },
  },

  MuiDrawer: {
    styleOverrides: {
      paper: {
        '@media (max-width:800px)': {
          width: '100%',
        },
      },
    },
  },

})

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FF9215',
      dark: '#FE7312',
      light: '#FFD655',
      contrastText: '#ffffff',
      contrast: '#ffffff',
    },
    secondary: {
      main: '#1582FF',
      dark: '#225DD8',
      light: '#5AB2FF',
      contrastText: '#ffffff',
    },
    error: {
      main: red[700],
      dark: red[800],
      light: red[400],
      contrastText: '#ffffff',
    },
    background: {
      default: '#EBEBEB',
      paper: '#ffffff',
    },
    text: {
      primary: alpha('#000000', 0.87),
      secondary: alpha('#000000', 0.6),
      disabled: alpha('#000000', 0.38),
    },
  },
  typography,
  components: components({
    primary: { main: '#FF9215', dark: '#FE7312', light: '#FFD655' },
    text: { primary: alpha('#000000', 0.87) },
  }),
})

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FF8C00',
      dark: '#C65A00',
      light: '#FFAA4D',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#1582FF',
      dark: '#225DD8',
      light: '#5AB2FF',
      contrastText: '#ffffff',
    },
    error: {
      main: red[700],
      dark: red[800],
      light: red[400],
      contrastText: '#ffffff',
    },
    background: {
      default: '#1d1d1d',
      paper: '#222222',
    },
    text: {
      primary: alpha('#ffffff', 0.87),
      secondary: alpha('#ffffff', 0.6),
      disabled: alpha('#ffffff', 0.38),
    },
  },
  typography,
  components: components({
    primary: { main: '#FF8C00', dark: '#C65A00', light: '#FFAA4D' },
    text: { primary: alpha('#000000', 0.87) },
  }),
})


const compileTheme = (session, mode) => {
  if (!session || (!session.theme?.light && !session.theme?.dark)) {
    return lightTheme;
  }

  const themeTemplate = mode === 'dark' ? session.theme?.dark : session.theme?.light;

  try {
    const compiled = _.template(themeTemplate);
    const compiledTemplateString = compiled({ red, alpha });
    const cleanedTemplateString = compiledTemplateString
      .replace(/,\s*}/g, '}') 
      .replace(/,\s*]/g, ']');

    const parsedTheme = JSON.parse(cleanedTemplateString);

    const updatedTheme = createTheme({
      ...lightTheme,
      palette: {
        ...lightTheme.palette, //preserve additional colors
        ...parsedTheme.palette,
      },
      typography: {
        ...lightTheme.typography,
        ...parsedTheme.typography,
      },
      components: {
        ...lightTheme.components,
        ...components(parsedTheme.palette || lightTheme.palette, lightTheme.palette),
      },
    });

    return updatedTheme;
  } catch (error) {
    console.error('Error parsing theme:', error);
    return lightTheme; 
  }
};


export {
  lightTheme,
  darkTheme,
  compileTheme,
}
