import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'leave-request-rollover'

const initialState = {
  list: null,
}

export const search = createApiAction(
  'leave/leave-request-rollover/search',
  client.search('/api/leave/leave-request-rollover'),
)
export const save = createApiAction(
  'leave/leave-request-rollover/save',
  client.save('/api/leave/leave-request-rollover'),
)

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    initLeaveRequestRollover: state => {
      state.list = null
    },
  },
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload
    }).addCase(save.fulfilled, (state, action) => {
      state.list = action.payload
    })
  },
})

export const { initLeaveRequestRollover } = sessionSlice.actions
export default sessionSlice.reducer
