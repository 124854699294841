import { Autocomplete, Box, Button, Grid, MenuItem, Stack, TextField } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider, useMember } from 'components/Providers'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguages, getRelationshipTypes } from 'store/lookup'
import { useEffect, useState } from 'react'
import { destroy as deleteContact, save as saveContact, search as getMemberContacts } from 'store/member/contact'
import { omit } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { SubmitButton } from 'components/Buttons'
import Confirm from 'components/Dialog'
import { DeleteIcon } from 'components/Icons'
import { useParams } from 'react-router-dom'

const titles = ['Mr.', 'Ms.', 'Mrs.']

export default function ContactsForm({ data }) {
  const dispatch = useDispatch()
  const [confirm, setConfirm] = useState(false)
  const { memberId, contactId } = useParams()

  const member = useMember()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const relationshipTypes = useSelector(state => state.lookup.relationshipTypes)
  const languages = useSelector(state => state.lookup.languages)

  const basePath = `/member/${member?.person?.type}/${member?.id}/contacts`

  useEffect(() => {
    relationshipTypes || dispatch(getRelationshipTypes())
    languages || dispatch(getLanguages())
  }, [dispatch])

  const schema = yup.object().shape({
    relationship: yup.string().required('Required'),
    emergency: yup.boolean(),
    contact: yup.object().shape({
      title: yup.string().nullable(),
      email: yup.string().email().nullable(),
      firstName: yup
        .string()
        .nullable(false)
        .required('Required')
        .transform(value => value?.trim())
        .test('minLength', '', value => value?.trim().length >= 1),
      lastName: yup
        .string()
        .nullable(false)
        .required('Required')
        .transform(value => value?.trim())
        .test('minLength', '', value => value?.trim().length >= 1),
    }),
  })
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      contact: {
        firstName: data?.contact?.firstName,
        lastName: data?.contact?.lastName,
        title: data?.contact?.title,
        phoneNumber: data?.contact?.phones[0]?.number,
      },
      relationship: data?.relationship,
    },
  })

  const onDestroy = async () => {
    setConfirm(false)
    await dispatch(deleteContact({ type: member?.person?.type, memberId: member?.id, id: contactId }))
    await dispatch(getMemberContacts({ type: 'staff', memberId }))
    navigate(basePath)
  }

  const onSubmit = async formData => {
    let datas = omit(formData, ['contactId'])
    datas.contact = omit(datas.contact, ['id', 'user'])
    datas.contact.phones = []
    datas.contact.phones.push({
      id: data?.contact?.phones[0]?.id,
      type: 'mobile',
      number: formData?.contact?.phoneNumber,
    })

    if (data) {
      datas.contact.id = data.contact.id
    }
    if (!datas?.contact?.type) {
      datas.contact.type = 'contact'
    }
    const res = await dispatch(
      saveContact({
        id: data?.id || null,
        type: member?.person?.type,
        memberId: member?.id,
        ...omit(datas, 'contact.phoneNumber'),
      }),
    )
    if (res.payload) {
      navigate(basePath)
    } else {
      console.error(res.errors)
    }
  }
  const onErrors = errors => console.log(errors)

  if (!(languages && relationshipTypes)) return null

  return (
    <BasePathProvider value={`${basePath}/profile`}>
      <Box
        data-component="MemberEditForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ py: 2, overflowY: 'scroll', border: 'none' }}
      >
        {/* <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {guardian ? 'Parent/Guardian Information' : 'Additional Contact Information'}
          </Typography>
          <Header sx={{ pt: 2, pb: 3, pr: 0 }} close /> */}

        <Header title={'Contact Information'} small close borderBottom />
        <Box sx={{ px: 3, pt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4}>
              <Controller
                name="contact.title"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <Autocomplete
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disablePortal
                      {...field}
                      size="small"
                      fullWidth
                      freeSolo
                      options={titles || []}
                      onChange={(event, value) => {
                        value ? setValue('contact.title', value) : null
                      }}
                      onInputChange={(event, value, reason) => {
                        switch (reason) {
                          case 'reset':
                            break
                          case 'clear':
                            setValue('contact.title', null)
                            break
                          case 'input':
                            setValue('contact.title', value)
                            break
                          default:
                            break
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          {...field}
                          fullWidth
                          label={t('Title')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Controller
                name="contact.firstName"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField size="small" fullWidth label={t('First Name')} error={!!fieldState.error} {...field} />
                  )
                }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Controller
                name="contact.lastName"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField size="small" fullWidth label={t('Last Name')} error={!!fieldState.error} {...field} />
                  )
                }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Controller
                name="relationship"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      select
                      label={t('Relationship')}
                      error={!!fieldState.error}
                      {...field}
                    >
                      {relationshipTypes &&
                        relationshipTypes.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Controller
                name="contact.phoneNumber"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField size="small" fullWidth label={t('Phone Number')} error={!!fieldState.error} {...field} />
                  )
                }}
              />
            </Grid>
          </Grid>
          <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ my: 4 }}>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => setConfirm(true)}
              sx={{ mr: 2 }}
              disabled={data?.id ? false : true}
            >
              <DeleteIcon /> {t('DELETE')}
            </Button>
            <SubmitButton size="small" color="primary" title={t('SAVE')} onClick={handleSubmit(onSubmit, onErrors)} />
          </Stack>
        </Box>
      </Box>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onDestroy} />
    </BasePathProvider>
  )
}
