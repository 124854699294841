import { Avatar, Box, LinearProgress, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import CustomAvatar from 'components/Avatar'
import { ROWS_PER_PAGE_OPTIONS } from 'constants'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getClients, getJobTitles } from 'store/lookup'
import { search, setSearchPersonLimit, setSearchPersonOffset, setSearchPersonQuery } from 'store/person/index'

export default function PersonList() {
  const dispatch = useDispatch()
  const query = useSelector(state => state.person2.query)
  const { list, count } = useSelector(state => state.person2)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { clients, jobTitle: jobTitles } = useSelector(state => state.lookup)

  useEffect(() => {
    clients || dispatch(getClients())
    jobTitles || dispatch(getJobTitles())
  }, [Boolean(clients), Boolean(jobTitles)])

  const filterNullValues = obj => {
    let temp = {}
    Object.keys(obj).forEach(key => {
      obj[key] !== null && obj[key] !== '' ? (temp[key] = obj[key]) : null
    })
    return temp
  }

  useEffect(() => {
    if (typeof query?.que === 'string' && query?.que?.trim().length > 2) {
      dispatch(search({ ...filterNullValues(query) }))
    }
  }, [query?.que])

  useEffect(() => {
     dispatch(search({ ...filterNullValues(query) }))
  }, [query?.limit, query?.offset, query?.sortBy, query?.sortOrder])

  const membershipList = (memberships, id) => {
    if (memberships?.length === 0) {
      return null
    } else if (memberships?.length === 1) {
      return (
        <Typography variant="body2">
          {memberships[0]?.district?.abbreviation
            ? memberships[0]?.district?.abbreviation
            : memberships[0]?.district?.name}
        </Typography>
      )
    } else if (memberships?.length > 1) {
      return (
        <Tooltip
          id={id}
          placement='bottom'
          title={memberships.map((item, index) => (
            <Typography variant="body2" sx={{ p: 0.5 }} key={index}>
              {item?.district?.abbreviation || item?.district?.name}
            </Typography>
          ))}
        >
          <Stack flexDirection={'row'}>
            <Typography variant="body2" aria-describedby={id}>
              {memberships[0]?.district?.abbreviation
                ? memberships[0]?.district?.abbreviation
                : memberships[0]?.district?.name}
            </Typography>
            <Typography variant="body2" aria-describedby={id} >
              ...
            </Typography>

          </Stack>
        </Tooltip>
      )
    }
  }

  const campusEnrollment = (schoolEnrollments, id) => {
    const campusEnrollments = []
    schoolEnrollments.forEach(item => {
      if (item.campusEnrollments) {
        item.campusEnrollments.map(ce => campusEnrollments.push(ce))
      }
    })
    if (campusEnrollments.length === 0) {
      return "N/A"
    } else if (campusEnrollments.length === 1) {
      return <Typography variant="body2">{campusEnrollments[0]?.campus?.name}</Typography>
    } else {
      return (
        <Tooltip
          id={id}
          placement='bottom'
          title={campusEnrollments.map((item, index) => (
            <Typography variant="body2" sx={{ p: 0.5 }} key={index}>
              {item.campus.name}
            </Typography>
          ))}
        >
          <Stack flexDirection={'row'}>
            <Typography variant="body2" aria-describedby={id} >
              {campusEnrollments[0]?.campus?.name}
            </Typography>
            <Typography variant="body2" aria-describedby={id} >
              ...
            </Typography>
          </Stack>
        </Tooltip>
      )
    }
  }

  const tableColumns = [
    {
      field: 'profilePicture',
      headerName: '',
      width: 60,
      editable: false,
      renderCell: params => (
        <Stack justifyContent={'center'} alignItems={'center'} sx={{ height: '100%' }}>
          <Avatar
            sx={{
              '&:hover': { transform: 'scale(2)', zIndex: 100 },
              transition: 'transform 0.2s ease-in-out',
              transform: 'scale(1)',
              width: 50,
              height: 50,
            }}
            src={params.row.profilePicture?.location}
            {...CustomAvatar(`${params.row.firstName} ${params.row.lastName}`)}
          >
            {[params.row.firstName?.[0], params.row.lastName?.[0]].join('')}
          </Avatar>
        </Stack>
      ),
    },
    {
      field: 'name',
      headerName: t('Full Name'),
      renderCell: params => (
        <Box sx={{ cursor: 'pointer' }}>{`${params.row.firstName || ''} ${params.row.lastName || ''}`}</Box>
      ),
      flex: 1,
    },
    {
      field: 'stateid',
      headerName: t('State ID'),
      editable: false,
      renderCell: params => params.row.memberships[0]?.stateId || ' ',
      flex: 0.6,
      sortable: true,
    },
    {
      field: 'phoneExtension',
      headerName: t('Extension'),
      flex: 0.6,
      editable: false,
      sortable: false,
      renderCell: params => params.row.memberships[0]?.phoneExtension || ' ',
    },
    {
      field: 'email',
      headerName: t('Email'),
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: 'jobtitleid',
      headerName: t('Job Title'),
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => jobTitles?.find(jt => jt.id === params.row.memberships[0]?.jobTitleId)?.name || ' ',
    },
    {
      field: 'district',
      headerName: t('District'),
      flex: 0.6,
      editable: false,
      sortable: false,
      renderCell: params => (
        <Stack justifyContent={'center'} alignItems={'flex-start'} sx={{ height: '100%' }}>
          {membershipList(params.row.memberships, params.row.id) || ' '}
        </Stack>
      ),
    },
    {
      field: 'campus',
      headerName: t('Campus'),
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: params => (
        <Stack justifyContent={'center'} alignItems={'flex-start'} sx={{ height: '100%' }}>
          {campusEnrollment(params.row.schoolEnrollments, params.row.id) || ' '}
        </Stack>
      ),
    },
  ]

  const pageSizeOptions = _.uniq([query?.limit, ...ROWS_PER_PAGE_OPTIONS])

  const handleCellClick = params => {
    if (
      params.field === 'name' ||
      params.field === 'profilePicture' ||
      params.field === 'stateid' ||
      params.field === 'email' ||
      params.field === 'jobtitleid' ||
      params.field === 'phoneExtension'
    ) {
      navigate(`/person/${params.id}`)
    }
  }

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  })

  const onPaginationModelChange = params => {
    if (params.pageSize !== paginationModel?.pageSize) {
      dispatch(setSearchPersonLimit(params.pageSize))
      dispatch(setSearchPersonOffset(0))
      setPaginationModel({ ...paginationModel, ...params, page: 0 })
    } else {
      dispatch(setSearchPersonLimit(params.pageSize))
      dispatch(setSearchPersonOffset(params.pageSize * params.page))
      setPaginationModel({ ...paginationModel, ...params })
    }
  }

  const handleSortModelChange = params => {
    if (params.length > 0) {
      const sortBy = params[0]?.field
      const sortOrder = params[0]?.sort
      dispatch(setSearchPersonQuery({ ...query, sortBy, sortOrder }))
    }
  }

  if (!(clients && jobTitles && list)) return <LinearProgress sx={{ my: 2}} color="primary" />

  return (
    <Box sx={{ p: 1 }}>
      <DataGrid
        onCellClick={handleCellClick}
        rows={list}
        columns={tableColumns}
        rowCount={count}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={pageSizeOptions}
        page={query.offset / query.limit}
        pagination
        disableColumnMenu
        disableRowSelectionOnClick
        rowHeight={60}
        sx={{ height: query.limit * 61 + 100 }}
        slotProps={{
          row: {
            style: { cursor: 'pointer' },
          },
        }}
      />
    </Box>
  )
}
