import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import * as action from 'store/member'
import { Fragment } from 'react'
import _ from 'lodash'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Avatar,
} from '@mui/material'
import CustomAvatar from 'components/Avatar'
import 'utils/global.scss'
import { useMembershipType } from 'components/Providers'
import { ROWS_PER_PAGE_OPTIONS } from 'constants'

function MemberList() {
  const dispatch = useDispatch()
  const membershipType = useMembershipType()
  const { list, count } = useSelector(state => state.member)
  const query = useSelector(state => state.member.query[membershipType])
  const navigate = useNavigate()

  const { t } = useTranslation()

  const openDetailPage = ({ id, type }) => {
    const url = ['/member', type, id].join('/')
    navigate(url)
  }

  const handleChangePage = (event, page) => {
    const limit = query?.limit || 10
    dispatch(action.search({ ...query, type: membershipType, limit, offset: limit * page }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(action.search({ ...query, type: membershipType, offset: 0, limit }))
  }
  const rowsPerPageOptions = _.uniq([query?.limit || ROWS_PER_PAGE_OPTIONS]);

  return (
    <Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>&nbsp;</TableHeadCell>
              <TableHeadCell className="font-weight-500">{t('Full Name')}</TableHeadCell>
              <TableHeadCell className="font-weight-500">
                {membershipType === 'staff' ? t('Staff ID') : t('Student Id')}
              </TableHeadCell>
              <TableHeadCell className="font-weight-500">
                {membershipType === 'staff' ? t('Email') : t('Grade Section')}
              </TableHeadCell>
              <TableHeadCell className="font-weight-500">
                {membershipType === 'staff' ? t('Phone Extension') : t('Email')}
              </TableHeadCell>
              {membershipType === 'staff' ? (
                <TableHeadCell className="font-weight-500">{t('Job Title')} </TableHeadCell>
              ) : null}
              <TableHeadCell></TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(({ id, person, schoolEnrollment, academicYearEnrollment }) => (
              <TableRow
                key={id}
                hover
                onClick={() => openDetailPage({ id, type: person?.type })}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell sx={{ cursor: 'pointer', width: '4em', py: '0.6em' }}>
                  <Avatar
                    sx={{
                      '&:hover': { transform: 'scale(2)', zIndex: 100 },
                      transition: 'transform 0.2s ease-in-out',
                      transform: 'scale(1)',
                      width: 50,
                      height: 50,
                    }}
                    src={person?.profilePicture?.location}
                    {...CustomAvatar(`${person?.firstName} ${person?.lastName}`)}
                  >
                    {[person?.firstName?.[0], person?.lastName?.[0]].join('')}
                  </Avatar>
                </TableCell>
                <TableCell>
                  {person?.firstName} {person?.middleName ?? ''} {person?.lastName}
                </TableCell>
                <TableCell>{membershipType === 'staff' ? person?.stateId : schoolEnrollment?.enrollmentId}</TableCell>
                <TableCell>
                  {membershipType === 'staff'
                    ? person.email
                    : `${academicYearEnrollment?.gradeLevelId ?? ''} ${academicYearEnrollment?.gradeSection ? `Section ${academicYearEnrollment?.gradeSection}` : ''
                    }`}
                </TableCell>
                {membershipType === 'staff' ? <TableCell></TableCell> : <TableCell>{person?.email}</TableCell>}
                {membershipType === 'staff' ? <TableCell>{person?.jobTitle?.name}</TableCell> : null}
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count || 0}
          page={query?.limit ? query?.offset / query?.limit : 0}
          onPageChange={handleChangePage}
          rowsPerPage={query?.limit || 10}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </TableContainer>
    </Fragment>
  )
}

export default MemberList
