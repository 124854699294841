import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TextField,
  IconButton,
  Grid,
  CardActions,
  CardContent,
  Box,
  MenuItem,
  InputAdornment,
  Chip,
  InputLabel,
  FormControl,
  Select,
  Autocomplete,
} from '@mui/material'
import Header from 'components/Header'
import { useForm, Controller, useFieldArray, useFormState, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import InviteIcon from '@mui/icons-material/Send'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { DatePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import { sendInvitation } from 'store/person'
import { save, create } from 'store/member/personal'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { SubmitButton } from 'components/Buttons'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import AddressList from '../Address/AddressList'
// import PhoneList from '../Phone/PhoneList'
import _ from 'lodash'
import {
  getDepartment,
  getEvaluation,
  getJobPositions,
  getJobTitles,
  getGenderTypes,
  getEthnicityTypes,
  getRaceTypes,
} from 'store/lookup'

const suffixes = ['Jr.', 'Sr.', 'II', 'III']

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function Personal() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()
  const basePath = useBasePath()
  const { membershipType, memberId } = useParams()

  const { raceTypes, ethnicityTypes, genderTypes } = useSelector(state => state.lookup)
  const { country, state } = useSelector(state => state.session.district.address)
  const member = useSelector(state => state.member.list?.find(m => m.id === memberId))
  const gradeLevelSection = useSelector(state => state.settings.gradeLevelSection.list)

  const jobTitles = useSelector(state => state.lookup.jobTitle)
  const jobPositions = useSelector(state => state.lookup.jobPosition)
  const departments = useSelector(state => state.lookup.department)
  const evaluations = useSelector(state => state.lookup.evaluation)
  const [openRaceOptions, setOpenRaceOptions] = useState(false)

  const schema = yup
    .object()
    .shape({
      person: yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup
          .string()
          .required('Last Name is required')
          .transform(value => value || null),
        middleName: yup.string().nullable(),
        suffix: yup.string().nullable(),
        gender: yup.string().nullable(),
        ethnicity: yup.string().nullable(),
        email: yup
          .string()
          .email('Invalid email format')
          .nullable()
          .transform(value => value || null),
        password: yup
          .string()
          .nullable()
          .transform(value => value || null),
        dateOfBirth: yup
          .date()
          .nullable()
          .min(dayjs().subtract(membershipType === 'student' ? 25 : 90, 'year'), 'Invalid Date')
          .max(dayjs().subtract(membershipType === 'student' ? 3 : 20, 'year'), 'Invalid Date'),
        phones: yup
          .array()
          .of(
            yup.object().shape({
              type: yup.string(),
              number: yup.string(),
            }),
          )
          .min(0),
        addresses: yup.array().of(
          yup.lazy((value, originalValue) => {
            if (originalValue.path === 'person.addresses[0]' && membershipType === 'staff') {
              return yup.object().shape({
                type: yup.string().required('Type is required'),
                street: yup.string().required('Street is required'),
                line2: yup.string().nullable(),
                city: yup.string().required('City is required'),
                state: yup.string().required('State is required'),
                // country: yup.string().required('Country is required'),
                zip: yup.string().required('ZIP is required'),
              })
            }
            return yup.object().shape({
              type: yup.string(),
              street: yup.string(),
              line2: yup.string(),
              city: yup.string(),
              state: yup.string(),
              // country: yup.string(),
              zip: yup.string(),
            })
          }),
        ),
      }),
      academicYearEnrollment: yup.object().shape({
        gradeLevelId: yup.string().nullable(),
        gradeSection: yup.string().nullable(),
      }),
      schoolEnrollment: yup.object().shape({
        jobPositionId: yup.number().nullable(),
      }),
      stateData: yup
        .object()
        .shape({
          stateId: yup.string().nullable(),
        })
        .nullable(),
    })
    .required()

  const { control, handleSubmit, reset, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues:
      membershipType === 'staff'
        ? {
            person: member?.person,
            schoolEnrollment: { jobPositionId: member?.schoolEnrollment?.jobPosition?.id },
            membership: { jobTitleId: member?.membership?.jobTitleId },
          }
        : _.pick(member, 'person', 'academicYearEnrollment', 'stateData'),
  })

  const { dirtyFields } = useFormState({ control })

  const addressArrayControl = useFieldArray({ control, name: 'person.addresses', keyName: 'uuid' })
  const phoneArrayControl = useFieldArray({ control, name: 'person.phones', keyName: 'uuid' })
  const selectedGradeLevelId = useWatch({ control, name: 'academicYearEnrollment.gradeLevelId' })
  // const watchJobTitleId = useWatch({ control, name: 'membership.jobTitleId' })

  const gradeLevelIds = []

  gradeLevelSection?.forEach(item => {
    const existingObject = gradeLevelIds.find(obj => obj.id === item.gradeLevel.id)

    if (!existingObject) {
      gradeLevelIds.push({
        id: item.gradeLevel.id,
        name: item.gradeLevel.name,
      })
    }
  })

  useEffect(() => {
    console.log('member', member.person)
    jobTitles || dispatch(getJobTitles())
    jobPositions || dispatch(getJobPositions())
    departments || dispatch(getDepartment())
    evaluations || dispatch(getEvaluation())
    ethnicityTypes || dispatch(getEthnicityTypes())
    genderTypes || dispatch(getGenderTypes())
    raceTypes || dispatch(getRaceTypes())
  }, [])

  const gradeSections = gradeLevelSection
    ?.filter(item => item.gradeLevel.id === selectedGradeLevelId)
    .map(item => item.gradeSection)

  useEffect(() => {
    setValue('academicYearEnrollment.gradeSection', null)
  }, [selectedGradeLevelId])

  useEffect(() => {
    const { id } = getValues()
    if (id !== member?.id)
      reset(
        membershipType === 'staff'
          ? {
              person: member.person,
              schoolEnrollment: { jobPositionId: member?.schoolEnrollment?.jobPosition?.id },
              membership: { jobTitleId: member?.membership?.jobTitleId },
            }
          : _.pick(member, 'person', 'academicYearEnrollment', 'stateData'),
      )
  }, [reset, getValues, member])

  useEffect(() => {
    const minPhoneCount = membershipType === 'staff' ? 2 : 0
    const minAddressCount = 1

    const addFieldsIfNeeded = (arrayControl, minCount, defaultValues) => {
      const currentCount = arrayControl.fields.length
      const fieldsToAdd = Math.max(0, minCount - currentCount)

      for (let index = 0; index < fieldsToAdd; index++) {
        arrayControl.append(defaultValues)
      }
    }

    addFieldsIfNeeded(phoneArrayControl, minPhoneCount, { type: '', number: '' })

    addFieldsIfNeeded(addressArrayControl, minAddressCount, {
      type: 'home',
      street: '',
      line2: '',
      city: '',
      state,
      zip: '',
      country: '',
    })
  }, [membershipType, phoneArrayControl.fields.length, addressArrayControl.fields.length])

  const inviteHandle = () => {
    if (member.id) {
      dispatch(sendInvitation({ type: membershipType, personId: member.person.id }))
    }
  }

  const getDirtyValues = async function (dirtyFields, allValues) {
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues
    }
    return Object.fromEntries(
      await Promise.all(
        Object.keys(dirtyFields).map(async key => [key, await getDirtyValues(dirtyFields[key], allValues[key])]),
      ),
    )
  }

  const onSubmit = async data => {
    const allValues =
      membershipType === 'staff'
        ? { person: data.person, schoolEnrollment: data.schoolEnrollment, membership: data.membership }
        : { person: data.person, academicYearEnrollment: data.academicYearEnrollment, stateData: data.stateData }

    let changedFields = await getDirtyValues(dirtyFields, allValues)

    if (membershipType === 'staff') {
      if (changedFields.person?.phones) {
        changedFields.person.phones = changedFields.person?.phones.filter(item => item.type !== '')
      }
    }

    if (changedFields.person) {
      changedFields.person.suffix = data.person.suffix
    } else {
      changedFields = { ...changedFields, person: { ...changedFields.person, suffix: data.person.suffix } }
    }

    if (changedFields.academicYearEnrollment?.gradeLevelId && !changedFields.academicYearEnrollment?.gradeSection) {
      changedFields.academicYearEnrollment.gradeSection = null
    }

    try {
      let forNew = _.pick(data, 'person')?.person
      forNew.phones = forNew.phones.filter(item => item.number !== '' && item.number !== null && item.number.length > 0)

      const query = member?.id
        ? { ..._.omit(changedFields, 'person.profilePicture'), memberId: member?.id, type: membershipType }
        : { ...forNew, type: membershipType }

      const res = member?.id ? await dispatch(save(query)) : await dispatch(create(query))
      if (res.payload) navigate(`/member/${membershipType}/${res.payload.id || member?.id}/profile`)
    } catch (error) {
      console.error(error)
    }
  }

  const onErrors = errors => console.error(errors)

  const handleClickRaceOption = () => {
    setOpenRaceOptions(true)
  }

  const handleDeleteRaceOption = async selected => {
    const list = getValues()?.person?.raceTypeIds?.filter(item => item !== selected)
    await setValue('person.raceTypeIds', [...list], { shouldDirty: true })
  }

  const setJobTextfields = jobTitleId => {
    const positionId = jobTitles.find(jt => jt.id === +jobTitleId)?.jobPositionId
    const departmentId = jobTitles.find(jt => jt.id === +jobTitleId)?.departmentId
    const evaluationId = jobTitles.find(jt => jt.id === +jobTitleId)?.evaluationId
    if (positionId) {
      setValue('jobPositionId', positionId, { shouldValidate: true })
    }
    if (departmentId) {
      setValue('department', departmentId, { shouldValidate: true })
    }
    if (evaluationId) {
      setValue('evaluation', evaluationId, { shouldValidate: true })
    }
  }

  if (!(jobTitles && jobPositions && departments && evaluations)) return null

  return (
    <Box data-component="PersonEditForm" component="form" onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
      <BasePathProvider value={`${basePath}/profile`}>
        <Header
          title={`${membershipType === 'staff' ? t('Staff') : t('Student')} ${t('Personal Information')}`}
          small
          close
          borderBottom
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Controller
                name="person.firstName"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      required
                      autoFocus
                      fullWidth
                      label={t('First Name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="person.middleName"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      label={t('Middle Name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      value={field.value || ''}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="person.lastName"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      required
                      size="small"
                      fullWidth
                      label={t('Last Name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <Controller
                name="person.suffix"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <Autocomplete
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      size="small"
                      fullWidth
                      freeSolo
                      options={suffixes || []}
                      onChange={(event, value) => {
                        setValue('person.suffix', value)
                      }}
                      onInputChange={(event, value, reason) => {
                        switch (reason) {
                          case 'reset':
                            break
                          case 'clear':
                            setValue('person.suffix', null)
                            break
                          case 'input':
                            setValue('person.suffix', value)
                            break
                          default:
                            break
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          {...field}
                          fullWidth
                          label={t('Suffix')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="person.gender"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      select
                      label={t('Gender')}
                      value={value || null}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      {genderTypes?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="person.dateOfBirth"
                control={control}
                render={({ field, fieldState }) => {
                  const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
                  return (
                    <DatePicker
                      sx={datePickerSx}
                      slotProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                        textField: { size: 'small' },
                        field: {
                          fullWidth: true,
                          // margin: 'dense',
                          // variant: 'standard',
                          InputLabelProps: { shrink: true },
                        },
                      }}
                      label={t('Date of Birth')}
                      // margin='dense'
                      // variant='standard'
                      InputLabelProps={{ shrink: true }}
                      {...field}
                      value={field.value && dayjs(field.value)}
                      minDate={dayjs().subtract(membershipType === 'student' ? 25 : 90, 'year')}
                      maxDate={dayjs().subtract(membershipType === 'student' ? 3 : 20, 'year')}
                    />
                  )
                }}
              />
            </Grid>
            {membershipType === 'student' && (
              <Grid item xs={4} md={4}>
                <Controller
                  name="stateData.stateId"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        label={t('State ID')}
                        value={value}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            {/* {membershipType === 'staff' && member?.id && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="schoolEnrollment.jobPositionId"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label={t('Job Position')}
                        value={value}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                      >
                        {jobPositions?.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
            )} */}
            {membershipType === 'staff' && member?.id && (
              <>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="person.jobTitleId"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          size="small"
                          fullWidth
                          select
                          label={t('Job Title')}
                          value={value}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={setJobTextfields(value)}
                          {...field}
                        >
                          {jobTitles?.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Controller
                    name="jobPositionId"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          disabled
                          value={jobPositions.find(jp => jp.id === +value)?.name}
                          size="small"
                          fullWidth
                          label={t('Job Position')}
                          InputLabelProps={{ shrink: true }}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Controller
                    name="department"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          value={departments.find(dm => dm.id === +value)?.name}
                          disabled
                          size="small"
                          fullWidth
                          label={t('Department')}
                          InputLabelProps={{ shrink: true }}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Controller
                    name="evaluation"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          value={evaluations.find(ev => ev.id === +value)?.name}
                          disabled
                          size="small"
                          fullWidth
                          label={t('Evaluation')}
                          InputLabelProps={{ shrink: true }}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
              </>
            )}
            {membershipType === 'student' && (
              <Grid item xs={4} md={4}>
                <Controller
                  name="academicYearEnrollment.gradeLevelId"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label={t('Grade')}
                        value={value || ''}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                      >
                        {gradeLevelIds?.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
            )}
            {membershipType === 'student' && (
              <Grid item xs={4} md={4}>
                <Controller
                  name="academicYearEnrollment.gradeSection"
                  control={control}
                  defaultValue={null}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label={t('Grade Section')}
                        value={value || ''}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        defaultValue={null}
                        {...field}
                      >
                        {gradeSections?.map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Controller
                name="person.ethnicity"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      select
                      label={t('Ethnicity')}
                      value={value || null}
                      defaultValue={value || null}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      {ethnicityTypes?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="person.raceTypeIds"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="demo-multiple-chip-label" sx={{ width: 300, mb: 1, top: '-7px' }}>
                        Race
                      </InputLabel>
                      <Select
                        open={openRaceOptions}
                        onClose={() => setOpenRaceOptions(false)}
                        onOpen={() => setOpenRaceOptions(true)}
                        size="small"
                        multiple
                        label={t('Race')}
                        error={!!fieldState.error}
                        value={value || []}
                        {...field}
                        renderValue={selected => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected?.map(select => {
                              const found = raceTypes?.find(race => race.id === select)
                              return found ? (
                                <Chip
                                  key={select}
                                  variant="outlined"
                                  label={`${found.name}`}
                                  onClick={() => handleClickRaceOption()}
                                  onDelete={() => handleDeleteRaceOption(select)}
                                  onMouseDown={event => {
                                    event.stopPropagation()
                                  }}
                                />
                              ) : null
                            })}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {raceTypes?.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="person.email"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      label={t('Email')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => inviteHandle()} edge="end">
                              <InviteIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="person.password"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      label={t('Password')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      defaultValue={null}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <AddressList
                control={control}
                path="person.addresses"
                {...addressArrayControl}
                country={country}
                state={state}
              />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <PhoneList control={control} path="person.phones" {...phoneArrayControl} />
            </Grid> */}
            <Grid item xs={12}>
              {/*               <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                {membershipType === 'staff' && (
                  <>
                    <Button
                      onClick={() =>
                        addressArrayControl.append({
                          type: 'home',
                          street: '',
                          city: '',
                          state,
                          zip: '',
                          country: '',
                        })
                      }
                    >
                      + {t('Address')}
                    </Button>
                    <Button onClick={() => phoneArrayControl.append({ type: '', number: '' })}>+ {t('Phone')}</Button>
                  </>
                )}
              </Stack> */}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', mr: 1 }}>
          <SubmitButton
            color="primary"
            size="small"
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit, onErrors)}
            title={t('SAVE')}
          />
        </CardActions>
      </BasePathProvider>
    </Box>
  )
}
