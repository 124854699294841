import 'utils/global.scss'
import * as yup from 'yup'
import { useEffect, useState, useRef } from 'react'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { BasePathProvider } from 'components/Providers'
import { ConfirmLeave as Confirm } from 'components/Dialog'
import {
  Box,
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from '@mui/material'
import DateRangePicker from 'components/DateRangePicker/DateRangePicker'
import dayjs from 'dayjs'
import RequestPolicy from './RequestPolicy'
import { save, get } from 'store/leave/leave-request'
import { DatePicker } from '@mui/x-date-pickers'
import { CloseIcon } from 'components/Icons'
import { search as searchRollover } from 'store/leave/leave-request-rollover'

export default function Form({ leaveRequestTypeList, selectedTeacher, events }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [show, setShow] = useState()
  const [scroll, setScroll] = useState('paper')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)

  const [isChecked, setIsChecked] = useState(false)
  const { district } = useSelector(state => state.session)
  const [dateRangeInsert, setDateRangeInsert] = useState([dayjs(), dayjs()])
  const leaveDates = useSelector(state => state.leave.leaveRequest.allItems)
  const validation = useSelector(state => state.leave.leaveRequest.validation)
  const sessionAcademicYear = useSelector(state => state.session.academicYear)
  const submitButtonRef = useRef(null)

  const basePath = `/settings/leave-request/`
  const leaveRequest = useSelector(state => state.leave.leaveRequest.list)?.find(item => item.id === parseInt(id))

  const schema = yup.object().shape({
    typeId: yup.string().required(),
    note: yup.string().trim().nullable(),
  })

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      typeId: leaveRequest?.typeId,
      note: leaveRequest?.note,
      date: dayjs.tz(leaveRequest?.leaveDate, district.timeZone).format('MM/DD/YYYY') || null,
    },
  })

  useEffect(() => {
    if (parseInt(id) && selectedTeacher) {
      dispatch(
        get({
          id,
        }),
      )
    }
  }, [selectedTeacher, dateRangeInsert?.[0], dateRangeInsert?.[1]])

  useEffect(() => {
    if (parseInt(id)) {
      setIsUpdate(true)
      setIsChecked(true)
    }
  }, [id])

  useEffect(() => {
    if (!isSubmitted) {
      return
    }

    setIsSubmitted(false)
    if (validation) {
      setConfirm(true)
    }
  }, [validation])

  useEffect(() => {
    if (isConfirmed && submitButtonRef.current) {
      submitButtonRef.current.click() // form submit
    }
  }, [isConfirmed])

  const onConfirm = () => {
    setConfirm(false)
    setIsConfirmed(true)
  }

  const onSubmit = async formData => {
    const data = {
      typeId: formData.typeId,
      note: formData.note,
      personId: selectedTeacher?.personId,
      isConfirmed,
    }
    if (id === 'new') {
      data.leaveStartDate = dateRangeInsert?.[0].format('YYYY-MM-DD')
      data.leaveEndDate = dateRangeInsert?.[1].format('YYYY-MM-DD')
    }
    if (parseInt(id)) {
      data.leaveDate = dayjs(formData.date).format('YYYY-MM-DD')
      data.id = id
    }

    setIsSubmitted(true)
    const result = await dispatch(save(data))
    if (!result.payload?.leaveRequestCheck) {
      navigate(`${basePath}`)
    }

    await dispatch(
      searchRollover({
        personId: selectedTeacher?.personId,
        academicYearProviderId: sessionAcademicYear.markingPeriodProvider?.academicYearId,
      }),
    )
  }

  const onErrors = errors => console.log(errors)

  const handleClickOpen = scrollType => {
    setShow(true)
    setScroll(scrollType)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Box
      data-component="LeaveRequestForm"
      component="form"
      onSubmit={handleSubmit(onSubmit, onErrors)}
      noValidate
      sx={{ overflowY: 'scroll', border: 'none' }}
    >
      <BasePathProvider value={`${basePath}`}>
        <Header title={t('Submit A Request for Staff Member')} small close borderBottom />
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <Controller
              name="typeId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t('Leave Request Type')}
                    placeholder={t('Leave Request Type')}
                    color="primary"
                    error={!!fieldState.error}
                    className="custom-text-field"
                    value={field.value}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    {leaveRequestTypeList?.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          {id === 'new' ? (
            <Grid item xs={12} md={12}>
              <DateRangePicker
                setDateRange={setDateRangeInsert}
                leaveDates={leaveDates}
                eventDates={events}
                minDate={sessionAcademicYear.startDate}
                maxDate={sessionAcademicYear.endDate}
              />
            </Grid>
          ) : (
            <></>
          )}
          {parseInt(id) ? (
            <Grid item sm={12} xs={12}>
              <Controller
                name="date"
                control={control}
                render={({ field, fieldState }) => {
                  const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
                  return (
                    <DatePicker
                      required
                      sx={datePickerSx}
                      slotProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                        textField: { size: 'small' },
                        field: {
                          fullWidth: true,
                          InputLabelProps: { shrink: true },
                        },
                      }}
                      label={t('End Date')}
                      InputLabelProps={{ shrink: true }}
                      {...field}
                      value={field.value && dayjs(field.value)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disabled={true}
                    />
                  )
                }}
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <Controller
              name="note"
              control={control}
              render={({ field: { value, onChange, ...field } }) => {
                return (
                  <TextField
                    label={t('Notes')}
                    placeholder="Notes"
                    multiline
                    rows={4}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={isChecked}
                  disabled={isUpdate}
                  onChange={() => setIsChecked(prevState => !prevState)}
                />
                <Typography>
                  {t('I confirm that I have read the')}{' '}
                  <div onClick={handleClickOpen} style={{ display: 'inline', cursor: 'pointer', color: 'blue' }}>
                    {t('leave request policy')}.
                  </div>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ mr: 0.5, mb: 3 }}>
          <Button
            type="submit"
            ref={submitButtonRef}
            color="primary"
            size="small"
            variant="contained"
            sx={{ mr: 2 }}
            disabled={!isChecked}
          >
            {t('SUBMIT REQUEST')}
          </Button>
          <Button
            type="button"
            size="small"
            color="inherit"
            variant="contained"
            sx={{ mr: 2 }}
            onClick={() => navigate(`/settings/leave-request`)}
          >
            {t('CANCEL REQUEST')}
          </Button>
        </Stack>
        <Dialog
          open={show}
          onClose={() => setShow(false)}
          sx={{
            '& .MuiDialog-paper': {
              width: { xs: '90%', sm: '60%', md: '40%' },
              maxWidth: 'none',
            },
          }}
        >
          <DialogTitle>Leave Request Policy</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={theme => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers={scroll === 'paper'}>
            <RequestPolicy />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </BasePathProvider>
      <Confirm open={confirm} validation={validation} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </Box>
  )
}
