import { Grid, Typography } from '@mui/material'
import PreviewCard from 'components/PreviewCard'
import { useTranslation } from 'react-i18next'

export default function PersonalInfoCard({ member, membershipStateDataType, languages, onlyReadable }) {
  const { t } = useTranslation()
  return (
    <PreviewCard
      title={t('Personal Information')}
      size="large"
      buttonLabel="More"
      onlyReadable={onlyReadable}
      buttonNavigate={`/member/${member.person.type}/${member.id}/state-data/personal`}
    >
      <Grid container spacing={4}>
        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Lives With')}</Typography>
          <Typography variant="body1">
            {
              membershipStateDataType
                ?.filter(item => item.field === 'studentLivesWith')
                ?.find(item => item.code === member?.stateData?.studentInfo?.studentLivesWith)?.description
            }
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Resident County')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.residentInfo?.residentCounty}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Resident Municipality')}</Typography>
          <Typography variant="body1">
            {
              membershipStateDataType
                .filter(item => item.field === 'residentMunicipalCode')
                ?.find(item => item.code === member?.stateData?.residentInfo?.residentMunicipalCode)?.description
            }
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Native Language')}</Typography>
          <Typography variant="body1">
            {languages.find(item => item.id === member?.stateData?.studentInfo?.firstNativeLanguage)?.name}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Resident District')}</Typography>
          <Typography variant="body1">
            {
              membershipStateDataType
                .filter(item => item.field === 'residentDistrict')
                ?.find(item => item.code === member?.stateData?.residentInfo?.residentDistrict)?.description
            }
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Resident School')}</Typography>
          <Typography variant="body1">
            {
              membershipStateDataType
                .filter(item => item.field === 'residentSchool')
                ?.find(item => item.code === member?.stateData?.residentInfo?.residentSchool)?.description
            }
            &nbsp;
          </Typography>
        </Grid>
      </Grid>
    </PreviewCard>
  )
}
