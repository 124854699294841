import PreviewCard from 'components/PreviewCard'
import { useTranslation } from 'react-i18next'

export default function StaffCard({ member }) {
  const { t } = useTranslation()
  return (
    <PreviewCard
      title={t('Staff State Data')}
      buttonLabel="More"
      buttonNavigate={`/member/${member.person.type}/${member.id}/state-data/staff`}
    >
      Design waiting..
    </PreviewCard>
  )
}
