import { createElement } from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { FaHospitalUser, FaBiking, FaUmbrellaBeach, FaUmbrella, FaHandHoldingUsd } from 'react-icons/fa'

const iconMap = {
  FaHospitalUser,
  FaBiking,
  FaUmbrellaBeach,
  FaUmbrella,
  FaHandHoldingUsd,
}

export default function RequestCard({ data }) {
  const IconComponent = iconMap[data.icon]
  return (
    <Box sx={{display: 'flex', flexDirection: 'wrap', gap: '10px', width: '100%', minHeight: '100%'}}>
      <Box sx={{ p: '10px', border: '2px solid', borderColor: 'primary.main', borderRadius: '4px',  display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
        <Grid container spacing={1} >
          <Grid item xs={9}>
            <Grid item xs={9}>
              <Box sx={{ fontSize: 'body1' }}>{data.name}</Box>
            </Grid>
            <Grid item xs={9}>
              <Grid
                container
                alignItems="center"
                style={{
                  marginTop: '15px',
                  marginRight: '0px',
                  paddingRight: '0px',
                }}
              >
                <Grid item>
                  <Typography variant="h6" style={{ marginRight: '4px' }}>
                    {data.name === "Unpaid Days" ? `${data.used} Day(s)` : `${data.remaining} Day(s)` }
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" style={{ paddingTop: '10px', color: 'gray' }}>
                    {data.name === "Unpaid Days" ? 'Used' : 'Remaining'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ fontSize: '45px', display: 'flex', alignItems: 'center', color: 'primary.light', mt: 3}}>
              {IconComponent && createElement(IconComponent)}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px' }}>
              {data.currentYear && (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{data.currentYear}</Typography> 
                  <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '5px'  }}>Current Year</Typography>
                  <Divider orientation="vertical" flexItem sx={{  verticalAlign: 'bottom', backgroundColor: 'primary.light' }} />
                </>
              )}
              {(data.rolledOver !== undefined) && (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{data.rolledOver}</Typography> 
                  <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '5px'  }}>Rolled Over</Typography>
                  <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'primary.light' }} />
                </>
              )}
              {data.name === "Unpaid Days" ?
                (<span style={{ fontSize: '1.2rem' }}></span>) :
                (data.used !== undefined && (
                  <>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{data.used}</Typography> 
                    <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '5px'  }}>Used</Typography>
                  </>
                ))
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
