import { Grid } from '@mui/material'
import RequestCard from './RequestCard'
import { useSelector } from 'react-redux'

export default function RequestPresentation({rollover}) {
  const { district } = useSelector(state => state.session)
  const isServiceProvider = district?.isServiceProvider === true

  if (!rollover) return null
  
  let data = []
  data.push({
    name: 'Sick Days',
    currentYear: rollover.proratedSickDays,
    remaining: rollover.proratedSickDays + rollover.rolloverSickDays - rollover.usedSickDays,
    rolledOver: rollover.rolloverSickDays,
    used: rollover.usedSickDays,
    icon: 'FaHospitalUser',
  })

  data.push({
    name: 'Personal Days',
    currentYear: rollover.proratedPersonalDays,
    remaining: rollover.proratedPersonalDays + rollover.rolloverPersonalDays - rollover.usedPersonalDays,
    used: rollover.usedPersonalDays,
    icon: 'FaBiking',
  })

  if (isServiceProvider) {
    data.push({
      name: 'Vacation Days',
      currentYear: rollover.proratedVacationDays,
      remaining: rollover.proratedVacationDays + rollover.rolloverVacationDays - rollover.usedVacationDays,
      used: rollover.usedVacationDays,
      icon: 'FaUmbrellaBeach',
    })
}

  data.push({
    name: 'Wellness Days',
    currentYear: rollover.proratedWellnessDays,
    remaining: rollover.proratedWellnessDays + rollover.rolloverWellnessDays - rollover.usedWellnessDays,
    used: rollover.usedWellnessDays,
    icon: 'FaUmbrella',
  })

  data.push({
    name: 'Unpaid Days',
    used: rollover.usedUnpaidDays,
    icon: 'FaHandHoldingUsd',
  })

  return (
    <Grid container spacing={1} sx={{ mt: 1 }}>
      {data.map((item, index) => (
        <Grid key={index} item xs={12} sm={12} md={isServiceProvider ? 4 : 6} lg={isServiceProvider ? 2.4 : 3} >
          <RequestCard data={item} />
        </Grid>
      ))}
    </Grid>
  )
}
