import { Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SchedulingCourseSessionsListItem from './ListItem'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { getTeachers } from 'store/lookup'
import { ROWS_PER_PAGE_OPTIONS } from 'constants'

export default function SchedulingCourseSessionsList({ list, handleChangePage, handleChangeRowsPerPage }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { academicYear } = useSelector(state => state.session)

  const { query, count } = useSelector(state => state.settings.courseSession)
  const { query: teacherQuery, rows: teachers } = useSelector(state => state.lookup?.teachers)
  const rowsPerPageOptions = _.uniq([query?.limit, ...ROWS_PER_PAGE_OPTIONS])

  useEffect(() => {
    teachers || dispatch(getTeachers({ ...teacherQuery, offset: 0, limit: teacherQuery?.limit || 10 }))
  }, [])

  useEffect(() => {
    dispatch(getTeachers({ ...teacherQuery, offset: 0, limit: teacherQuery?.limit || 10 }))
  }, [academicYear])

  if (!list) return null

  if (!teachers) return null

  return (
    <Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Course Name')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Session Name')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Teacher')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Term')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Enrollment')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Periods')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Action')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, index) => (
              <SchedulingCourseSessionsListItem key={index} item={item} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count || 0}
          page={query?.limit ? query?.offset / query?.limit : 0}
          onPageChange={handleChangePage}
          rowsPerPage={query?.limit || 10}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Fragment>
  )
}
