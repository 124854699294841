import { combineReducers, configureStore } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import sessionReducer from './session'
// import personReducer from './person'
import person2Reducer from './person/index.js'
import membershipReducer from './membership'
import academicYearReducer from './settings/academic-year'
import markingPeriodReducer from './settings/marking-period'
import academicYearEnrollmentReducer from './academic-year-enrollment'
import courseSessionPeriodReducer from './settings/course-session-period'
import courseSessionEnrollmentReducer from './course-session-enrollment'
import lookupReducer from './lookup'
import academicReducer from './academic'
import settingsReducer from './settings'
import personAttendanceDailyReducer from './person-attendance-daily'
import personAttendancePeriodReducer from './person-attendance-period'
import personGradeReducer from './person-grade'
import memberReducer from './member'
import providerReducer from './provider'
import reportReducer from './report'
import nurseReducer from './nurse'
import incidentReducer from './discipline'
import leaveReducer from './leave'
import { error as errorSnackbar } from '../utils/snackbar'

const combinedReducer = combineReducers({
  session: sessionReducer,
  // person: personReducer,
  membership: membershipReducer,
  academicYear: academicYearReducer,
  markingPeriod: markingPeriodReducer,
  academicYearEnrollment: academicYearEnrollmentReducer,
  courseSessionPeriod: courseSessionPeriodReducer,
  courseSessionEnrollment: courseSessionEnrollmentReducer,
  lookup: lookupReducer,
  academic: academicReducer,
  settings: settingsReducer,
  personAttendanceDaily: personAttendanceDailyReducer,
  personAttendancePeriod: personAttendancePeriodReducer,
  personGrade: personGradeReducer,
  member: memberReducer,
  person2: person2Reducer,
  provider: providerReducer,
  report: reportReducer,
  nurse: nurseReducer,
  discipline: incidentReducer,
  leave: leaveReducer,
})

const rootReducer = (state, action) => {
  if (action.error?.status === 401) {
    state = undefined
    Cookies.remove('sessionid')
  }
  if (action.error) {
    if (action.type !== 'auth/login/rejected') errorSnackbar(action.error.data?.error || action.error?.message)
  }
  if (['session/logout'].includes(action.type)) {
    state = undefined
  } else if (
    [
      'session/reload',
      'session/register/fulfilled',
      'session/impersonate/fulfilled',
      'session/set-membership/fulfilled',
      'session/set-school/fulfilled',
      'session/set-academicyear/fulfilled',
    ].includes(action.type)
  ) {
    state = { session: { initialized: true } }
  }
  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
})

export const hasPermission = requires => {
  const { session } = store.getState()
  const args = Array.isArray(requires) ? requires : [requires]
  return args.filter(Boolean).reduce((aggr, arg) => {
    if (!aggr) return aggr
    if (typeof arg === 'function') return arg(session)
    return session.permissions[arg]
  }, true)
}

export default store
