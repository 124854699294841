import { combineReducers } from '@reduxjs/toolkit'

import schoolReducer from './school'
import districtReducer from './district'
import campusReducer from './campus'
import buildingReducer from './building'
import buildingRoomReducer from './building-room'
import bellScheduleReducer from './bell-schedule'
import bellSchedulePeriodReducer from './bell-schedule-period'
import courseSessionReducer from './course-session'
import academicYearReducer from './academic-year'
import markingPeriodReducer from './marking-period'
import courseSessionPeriodReducer from './course-session-period'
import courseSessionEnrollmentsReducer from './course-session-enrollments'
import courseSessionScheduleReducer from './course-session-schedule'
import schoolEnrollmentReducer from './school-enrollment'
import courseReducer from './course'
import roleReducer from './role'
import gradeLevelSectionReducer from './grade-level-section'
import jobPositionReducer from './job-position'
import jobTitleReducer from './job-title'
import gradingScaleReducers from './grading-scale'
import departmentReducer from './department'
import evaluationReducer from './evaluation'
import periodReducer from './periods'
import courseSessionEnrollmentReducer from './course-session-enrollment'
import gradingCoefficientReducers from './grading-coefficient'
import roomReducer from './room'
import gradingLetterReducers from './grading-letter'

import attendanceReducer from './attendance'

const combinedReducer = combineReducers({
  school: schoolReducer,
  schoolEnrollment: schoolEnrollmentReducer,
  district: districtReducer,
  building: buildingReducer,
  buildingRoom: buildingRoomReducer,
  campus: campusReducer,
  academicYear: academicYearReducer,
  markingPeriod: markingPeriodReducer,
  bellSchedule: bellScheduleReducer,
  bellSchedulePeriod: bellSchedulePeriodReducer,
  courseSession: courseSessionReducer,
  courseSessionPeriod: courseSessionPeriodReducer,
  courseSessionsEnrollments: courseSessionEnrollmentsReducer,
  schedule: courseSessionScheduleReducer,
  role: roleReducer,
  course: courseReducer,
  gradeLevelSection: gradeLevelSectionReducer,
  jobPosition: jobPositionReducer,
  gradingScale: gradingScaleReducers,
  jobTitles: jobTitleReducer,
  departments: departmentReducer,
  evaluations: evaluationReducer,
  period: periodReducer,
  courseSessionEnrollment: courseSessionEnrollmentReducer,
  gradingCoefficient: gradingCoefficientReducers,
  room: roomReducer,
  gradingLetter: gradingLetterReducers,
  attendance: attendanceReducer,
})

export default combinedReducer
