import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import Header from 'components/Header';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BasePathProvider, useBasePath } from 'components/Providers';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { pendingSave, searchPendingApprovals } from 'store/leave/leave-request';
import { useDispatch } from 'react-redux';

export default function LeaveApprovalForm({ pendingApproval }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const basePath = useBasePath();
    const navigate = useNavigate();

    const schema = yup.object().shape({
        approvalNote: yup.string().required('Required'),
    });

    const { control, handleSubmit } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        approvalNote: pendingApproval.approvalNote,
      },
    });

    const onSubmit = async formData => {
        const data = {
          id: pendingApproval.id,
          approvalStatusId: "rejected",  
          approvalNote: formData.approvalNote,
        };
            
        const result = await dispatch(pendingSave(data));
        if (result.payload) {
          navigate(`${basePath}`);
        }

        await dispatch(searchPendingApprovals());
    };

    return (
        <BasePathProvider value={`${basePath}`}>
            <Box sx={{ overflowY: 'scroll', border: 'none' }}>
                <Header title={`Deny ${pendingApproval.requester.lastName}, ${pendingApproval.requester.firstName}'s Leave Request`} small close borderBottom />
            </Box>
            <Box sx={{ overflowY: 'scroll', mt: 1, pl: 2, pr: 2}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', border: 'solid 1px rgba(0, 0, 0, 0.23)', p: 1, borderRadius: '5px' }}>
                    <Stack direction="column" spacing={0}>
                        <Typography variant="body2" color="grey">{t('Leave Type')}</Typography>
                        <Typography variant="body1">{`${pendingApproval.requester.lastName}, ${pendingApproval.requester.firstName}`}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={0}>
                        <Typography variant="body2" color="grey">{t('Leave Date')}</Typography>
                        <Typography variant="body1">{dayjs(pendingApproval.leaveDate).format('MM/DD/YYYY')}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={0}>
                        <Typography variant="body2" color="grey">{t('Notes')}</Typography>
                        <Typography variant="body1">{ pendingApproval.note }</Typography>
                    </Stack>
                </Box>
            </Box>
            <Box data-component="LeaveApprovalForm" elevation={0} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', p: 2 }}>
                    <Controller
                        name='approvalNote'
                        control={control}
                        rules={{ required: 'Field is required' }}
                        render={({ field: { onChange, value, ...field }, fieldState }) => {
                            return (
                                <TextField
                                    {...field} 
                                    onChange={onChange} 
                                    value={value}
                                    error={!!fieldState.error} 
                                    helperText={fieldState.error ? fieldState.error.message : ''} 
                                    label="Denial Reason" 
                                    variant="outlined" 
                                    multiline 
                                    rows={4}
                                />
                            );
                        }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', px: 1, py: 2 }}>
                        <Button variant='contained' size='medium' color='primary' type="submit" title="Submit">{t('Submit')}</Button>
                        <Button variant='contained' size='medium' color='inherit' onClick={() => navigate(basePath)} title="Cancel">{t('Cancel')}</Button>
                    </Box>
                </Box>
            </Box>
        </BasePathProvider>
    );
};
