import { useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { Link, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useBasePath, useMember } from 'components/Providers'
import { useSelector } from 'react-redux'
import Contacts from '../Contact'
import Documents from '../Document'
import FinalGrades from '../FinalGrades'
import { STUDENT } from 'constants'
import MemberProfile from './Profile'
import StateData from '../StateData'
import { Schedule } from '../Schedule'
import Enrollments from '../Enrollments'
import Attendance from '../Attendance'
// import Roles from '../Roles'
import 'utils/global.scss'

const PROFILE = 0
const CONTACTS = 1
const DOCUMENTS = 2
const ENROLLMENTS = 3 
const ATTENDANCE = 4
const SCHEDULE = 5
const FINALGRADES = 6
const STATEDATA = 7
// const ROLES = 8

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = [
    'profile',
    'contacts',
    'documents',
    'enrollments',
    'attendance',
    'schedule',
    'final-grades',
    'state-data',
    'roles',
  ].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function TabControl() {
  const { memberId, '*': tabName } = useParams()
  const theme = useSelector(state => state.session.appearance)
  const member = useMember()
  const permissions = useSelector(state => state.session?.permissions)
  const { district } = useSelector(state => state.session)

  const state = useSelector(state => state.session?.district?.address?.state || '')
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
  const basePath = useBasePath()
  const { t } = useTranslation()

  const membershipType = member?.person?.type
  const academicYearEnrollment = member?.academicYearEnrollment

  useEffect(() => {
    setTabIndex(getTabIndex(tabName))
  }, [tabName])

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', displayPrint: 'none' }}>
        {memberId && (
          <Tabs
            value={tabIndex}
            TabIndicatorProps={{ style: { background: 'primary', fontFamily: 'Roboto, sans-serif' } }}
          >
            <Tab
              sx={{ color: tabIndex === 0 && 'primary !important' }}
              className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
              label={t('Profile')}
              value={PROFILE}
              component={Link}
              to={`${basePath}/profile`}
            />
            {permissions[`${membershipType}.contact`] && (
              <Tab
                sx={{ color: tabIndex === 1 && 'primary !important' }}
                className={tabIndex === 1 ? `tab-item-selected` : `tab-item-${theme}`}
                label={t('Contacts')}
                value={CONTACTS}
                component={Link}
                to={`${basePath}/contacts`}
                disabled={String(memberId) === 'new'}
              />
            )}
            {permissions[`${membershipType}.document`] && membershipType === STUDENT && (
              <Tab
                sx={{ color: tabIndex === 2 && 'primary !important' }}
                className={tabIndex === 2 ? `tab-item-selected` : `tab-item-${theme}`}
                label={t('Documents')}
                value={DOCUMENTS}
                component={Link}
                to={`${basePath}/documents`}
                disabled={String(memberId) === 'new'}
              />
            )}
            {permissions[`${membershipType}.enrollment`] && membershipType === STUDENT && (
              <Tab
                sx={{ color: tabIndex === 3 && 'primary !important' }}
                className={tabIndex === 3 ? `tab-item-selected` : `tab-item-${theme}`}
                label={t('Enrollment')}
                value={ENROLLMENTS}
                component={Link}
                to={`${basePath}/enrollments`}
                disabled={String(memberId) === 'new'}
              />
            )} 
            {membershipType === STUDENT && academicYearEnrollment && (
              <Tab
                sx={{ color: tabIndex === 4 && 'primary !important' }}
                className={tabIndex === 4 ? `tab-item-selected` : `tab-item-${theme}`}
                label={t('Attendance')}
                value={ATTENDANCE}
                component={Link}
                to={`${basePath}/attendance`}
                disabled={String(memberId) === 'new'}
              />
            )}
            {!district?.isServiceProvider && academicYearEnrollment && (
              <Tab
                sx={{ color: tabIndex === 5 && 'primary !important' }}
                className={tabIndex === 5 ? `tab-item-selected` : `tab-item-${theme}`}
                label={t('Schedule')}
                value={SCHEDULE}
                component={Link}
                to={`${basePath}/schedule`}
                disabled={String(memberId) === 'new'}
              />
            )}
            {membershipType === STUDENT && academicYearEnrollment && (
              <Tab
                sx={{ color: tabIndex === 6 && 'primary !important' }}
                className={tabIndex === 6 ? `tab-item-selected` : `tab-item-${theme}`}
                label={t('Final Grades')}
                value={FINALGRADES}
                component={Link}
                to={`${basePath}/final-grades`}
                disabled={String(memberId) === 'new'}
              />
            )}
            {!district?.isServiceProvider && state.toLowerCase() === 'nj' && academicYearEnrollment && membershipType === STUDENT && (
              <Tab
                sx={{ color: tabIndex === 7 && 'primary !important' }}
                className={tabIndex === 7 ? `tab-item-selected` : `tab-item-${theme}`}
                label={t('State Data')}
                value={STATEDATA}
                component={Link}
                to={`${basePath}/state-data`}
                disabled={String(memberId) === 'new'}
              />
            )} 
            {/* {permissions[`${membershipType}.role`] && (
              <Tab
                sx={{ color: tabIndex === 8 && 'primary !important' }}
                className={tabIndex === 8 ? `tab-item-selected` : `tab-item-${theme}`}
                label={t('Roles')}
                value={ROLES}
                component={Link}
                to={`${basePath}/roles`}
                disabled={String(memberId) === 'new'}
              />
            )}  */}
          </Tabs>
        )}
      </Box>
      <Routes>
        <Route path="" element={<Outlet />}>
          <Route path="" element={<MemberProfile />} />
          <Route path="profile" element={<MemberProfile />} />
          <Route path="person" element={<MemberProfile />} />
          <Route path="contacts/*" element={<Contacts />} />
          <Route path="documents/*" element={<Documents />} />
          <Route path="enrollments/*" element={<Enrollments />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="attendance" element={<Attendance />} />
          <Route path="final-grades" element={<FinalGrades />} />
          <Route path="state-data/:part" element={<StateData />} />
          <Route path="state-data/*" element={<StateData />} />
          {/* <Route path="roles" element={<Roles />} /> */}
          <Route path="*" element={<MemberProfile />} />
        </Route>
      </Routes>
    </Box>
  )
}
