import { Route, Routes } from 'react-router-dom'
import LeaveApprovalsPage from './Page'
//import LeaveRequestForm from './Form'

export default function index() {
  return (
    <Routes>
      <Route path="/*" element={<LeaveApprovalsPage />}></Route>
      <Route path="/:id" element={<LeaveApprovalsPage />}></Route>
      {/* <Route path="/:id" element={<LeaveRequestForm />}></Route> */}
    </Routes>
  )
}

