import { Box, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TableHeadCell from "components/TableHeadCell";
import { useTranslation } from 'react-i18next'
import LeaveApproalListItem from "./ListItem";
import { useSelector, useDispatch } from "react-redux";
import { searchPendingApprovals } from "store/leave/leave-request";
import { useEffect } from "react";
import NoRecords from "components/NoRecords";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

const headers = ['Staff', 'Created', 'Leave Type', 'Leave Date', 'Notes', 'Document', 'Actions']
export default function LeaveApprovalsList(){
    const { t } = useTranslation()
    const dispatch = useDispatch()
    // const { academicYear } = useSelector(state => state.session);

    const pendingApprovals = useSelector(state => state.leave.leaveRequest.pendingApprovals)

    useEffect(() => {
        if (pendingApprovals && pendingApprovals.length === 0) {
            dispatch(searchPendingApprovals());
        }
    }, [dispatch, Boolean(pendingApprovals)]);

    return(
      <Box sx={{ display: 'flex', padding: '10px', flexDirection: 'column', alignItems: 'flex-start', gap: '10px', overflowY: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
        <CardContent sx={{ pr: 0, pl: 0 }}>
          <Table>
          <TableHead>
            <TableRow>
              {headers.map((item, index) => (
                <TableHeadCell key={index}>
                  {t(item)}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingApprovals && pendingApprovals.map((item, index) => (
              <LeaveApproalListItem key={index} item={item} />
             ))}
            
            {pendingApprovals && pendingApprovals.length === 0 && (
              <TableRow>
                <TableCell colSpan={headers.length} align="center">
                  <NoRecords 
                    message={`No Pending Leave Requests`}
                    icon={<SentimentSatisfiedAltIcon sx={{ fontSize: 40, mb: 1, color: "grey" }} />}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          </Table>
        </CardContent>
        </Grid>
      </Grid>
      </Box>
    )
}