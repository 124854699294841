import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'leave'

const initialState = {
  count: null,
  list: null,
  allItems: null, // This is used to store all leave requests for the all academic years
  pendingApprovals: null,
  pendingCount: null,
  query: { limit: 10, offset: 0, sortBy: null, sortOrder: null },
  validation: null,
}

export const search = createApiAction('leave/leave-request/search', client.search('/api/leave/leave-request'))
export const get = createApiAction('leave/leave-request/get', client.retrieve(`/api/leave/leave-request/:id`))
export const save = createApiAction('leave/leave-request/save', client.save('/api/leave/leave-request'))
export const destroy = createApiAction(
  'leave/leave-request/delete',
  client.destroy('/api/leave/leave-request/:id'),
)
export const searchAll = createApiAction(
  'leave/leave-request/searchAll',
  client.search('/api/leave/leave-request'), // This is used to store all leave requests for the all academic years
)

export const searchPendingApprovals = createApiAction('leave/leave-request/allPending', client.search('/api/leave/leave-request/allpending'))
export const getPending = createApiAction('leave/leave-request/getPending', client.retrieve(`/api/leave/leave-request/:id`))
export const pendingSave = createApiAction('leave/leave-request/pendingSave', client.save('/api/leave/leave-request'))

const updateList = (state, action) => {
  if (action.payload.leaveRequestCheck) {
    state.validation = action.payload.leaveRequestCheck
    return
  }

  if (Array.isArray(action.payload)) {
    action.payload.forEach(item => {
      const found = state.list?.find(leaveRequest => leaveRequest.id === item.id)
      state.list = found
        ? state.list?.map(leaveRequest => (found.id === leaveRequest.id ? item : leaveRequest))
        : [...state.list, item]
    })
    action.payload.forEach(item => {
      const found = state.allItems?.find(leaveRequest => leaveRequest.id === item.id)
      state.allItems = found
        ? state.allItems?.map(leaveRequest => (found.id === leaveRequest.id ? item : leaveRequest))
        : [...state.allItems, item]
    })
    
  } else {
    const found = state.list?.find(leaveRequest => leaveRequest.id === action.payload.id)
    state.list = found
      ? state.list?.map(leaveRequest => (found.id === leaveRequest.id ? action.payload : leaveRequest))
      : [...state.list, action.payload]

    state.allItems = found
      ? state.allItems?.map(leaveRequest => (found.id === leaveRequest.id ? action.payload : leaveRequest))
      : [...state.allItems, action.payload]

  } 

  // Order the state list by 'leaveDate' column
  state.list?.sort((a, b) => new Date(a.leaveDate) - new Date(b.leaveDate))
  state.count = state.list?.length
}

const updatePendingList = (state, action) => {
  const found = state.pendingApprovals?.find(rec => rec.id === action.payload.id)
  state.pendingApprovals = found
    ? state.pendingApprovals.map(item => (found.id === item.id ? action.payload : item))
    : [...state.pendingApprovals, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    initLeaveRequest: state => {
      state.list = null
      state.count = null
      state.query = { limit: 10, offset: 0, sortBy: null, sortOrder: null }
    },
    setSearchQuery: (state, action) => {
      state.query = action.payload
    },
    setSearchLimit: (state, action) => {
      state.query.limit = action.payload
    },
    setSearchOffset: (state, action) => {
      state.query.offset = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.query = action.meta.arg || initialState.count.query
        state.count = action.payload.count
        state.list = action.payload.rows
      })
      .addCase(searchAll.fulfilled, (state, action) => {
        state.allItems = action.payload.rows
      })
      .addCase(searchPendingApprovals.fulfilled, (state, action) => {
        state.pendingApprovals = action.payload.rows
        state.pendingCount = action.payload.count
      })
      .addCase(get.fulfilled, updateList)
      .addCase(getPending.fulfilled, updatePendingList)
      .addCase(pendingSave.fulfilled, updatePendingList)
      .addCase(save.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list?.filter(item => item.id !== action.payload.id)
        state.allItems = state.allItems?.filter(item => item.id !== action.payload.id)
      })
  },
})
export const { setSearchLimit, setSearchOffset, setSearchQuery, initLeaveRequest } = sessionSlice.actions

export default sessionSlice.reducer
