import { useNavigate, useParams } from 'react-router-dom'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Box, Drawer } from '@mui/material'
import { BasePathProvider, MembershipTypeProvider } from 'components/Providers'
import MemberSearch from './Search'
import Person from './Person'
import PageHeader from 'components/AppBar/PageHeader'

function Member() {
  const navigate = useNavigate()
  const { membershipType, memberId, } = useParams()
  const { t } = useTranslation()
  const basePath = `/member/${membershipType}`

  if (membershipType !== 'student' && membershipType !== 'staff') {
    navigate('/')
  }
  return (
    <BasePathProvider value={basePath}>
      <MembershipTypeProvider type={membershipType}>
        <Box sx={{ pb: 2 }}>
          <PageHeader title={t(`${capitalize(membershipType)} List`)} add />
        </Box>
        <MemberSearch />
        <Drawer open={Boolean(memberId)} anchor="right" onClose={() => navigate(basePath)}>
          <Person />
        </Drawer>
      </MembershipTypeProvider>
    </BasePathProvider>
  )
}

export default Member
