import { useEffect, useState } from 'react'
import { logout, setEnrollment, getEnrollment } from '../store/session'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Divider, LinearProgress, ListItemIcon, ListItemText, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Grid, IconButton, Typography, Menu, Avatar, Tooltip, MenuItem } from '@mui/material'
import { Link } from 'react-router-dom'
import { CheckIcon, FiBell } from '../components/Icons'
import { useSession } from '../components/Providers'
import { useTranslation } from 'react-i18next'
import { appearance } from 'store/session'
import { DarkMode, LightMode, SettingsBrightness } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { capitalize } from 'lodash'

const settings = [
  {
    title: 'Profile',
    path: '/settings/profile',
  },
  {
    title: 'Settings',
    path: '/settings',
  },
]
const languages = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Spanish' },
}

export default function UserMenu() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [anchorElUser, setAnchorElUser] = useState(null)
  const mode = useSelector(state => state.session.appearance)
  const navigate = useNavigate()
  const { user, enrollments, district, campus } = useSession()

  useEffect(() => {
    enrollments || dispatch(getEnrollment());
  }, [Boolean(enrollments)]);

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const handleModeChange = (event, mode) => {
    dispatch(appearance(mode))
  }
  const handleLangChange = (event, lang) => {
    i18n.changeLanguage(lang)
  }

  const menuItemList = () => {

    if (!enrollments) {
        return (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )
      }

    return enrollments.map(ms =>
      ms.campusId ? (
        <MenuItem
          key={`ms-${ms?.campusId}`}
          selected={ms?.campusId === campus?.id}
          onClick={() => {
            handleCloseUserMenu()
            navigate('/')
            dispatch(setEnrollment({ id: ms?.id }))
          }}
        >
          <ListItemText>
            {capitalize(ms.type)} - {ms.personName} @ {ms.campusName}
          </ListItemText>

          <ListItemIcon sx={{ ml: 2, minWidth: 24 }}>
            {ms?.campusId === campus?.id && <CheckIcon fontSize="small" color="primary" />}
          </ListItemIcon>
        </MenuItem>
      ) : (
        <MenuItem
          key={`ms-${ms.districtId}`}
          selected={ms?.districtId === district?.id}
          onClick={() => {
            handleCloseUserMenu()
            navigate('/')
            dispatch(setEnrollment({ id: ms?.id, campus: 'null' }))
          }}
        >
          <ListItemText>
            {capitalize(ms.type)} - {ms.personName} @ {ms.districtName}
          </ListItemText>

          <ListItemIcon sx={{ ml: 2, minWidth: 24 }}>
            {ms.districtId === district?.id && <CheckIcon fontSize="small" color="primary" />}
          </ListItemIcon>
        </MenuItem>
      ),
    )
  }

  return (
    <Grid container alignItems="center" justifyContent="center" sx={{ ml: 0, pl: -2 }}>
      <FiBell size="24px" />
      <Grid item sx={{ flexGrow: 0, ml: 2 }}>
        <Tooltip title={t('Open settings')}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar
              alt={user?.firstName}
              src={user?.avatar}
              sx={{ width: 40, height: 40 }}
            />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map(page => (
            <MenuItem key={page.path} component={Link} to={page.path} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{page.title}</Typography>
            </MenuItem>
          ))}
          <Divider />
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '16px' }}>
              <Typography textAlign="center">{t('Select Mode')}</Typography>
              <ToggleButtonGroup value={mode} exclusive onChange={handleModeChange} size="small">
                <ToggleButton value="">
                  <SettingsBrightness fontSize="small" />
                </ToggleButton>
                <ToggleButton value="light">
                  <LightMode fontSize="small" />
                </ToggleButton>
                <ToggleButton value="dark">
                  <DarkMode fontSize="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '16px' }}>
              <Typography textAlign="center">{t('Select Language')}</Typography>
              <ToggleButtonGroup value={i18n.resolvedLanguage} exclusive onChange={handleLangChange} size="small">
                {Object.keys(languages).map(lang => (
                  <ToggleButton value={lang} key={lang}>
                    {lang}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
          </div>
          <Divider />
          {menuItemList()}
          <Divider />
          <MenuItem onClick={() => dispatch(logout())}>
            <Typography textAlign="center">{t('Logout')}</Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  )
}
