import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import { search as searchStudent } from 'store/academic/student/teacher-student'
import { Fragment } from 'react'
import _ from 'lodash'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Avatar,
} from '@mui/material'
import CustomAvatar from 'components/Avatar'
import 'utils/global.scss'
import { ROWS_PER_PAGE_OPTIONS } from 'constants'

function StudentList() {
  const dispatch = useDispatch()
  const membershipType = useSelector(state => state.session.membership.type)
  const { list, count } = useSelector(state => state.academic.student)
  const query = useSelector(state => state.academic.student.query)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const openDetailPage = ({ id }) => {
    const url = ['/academic/student', id].join('/')
    navigate(url)
  }

  const handleChangePage = (event, page) => {
    const limit = query?.limit || 10
    dispatch(searchStudent({ ...query, limit, offset: limit * page }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(searchStudent({ ...query, offset: 0, limit }))
  }

  const rowsPerPageOptions = _.uniq([query?.limit, ...ROWS_PER_PAGE_OPTIONS]);

  return (
    <Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>&nbsp;</TableHeadCell>
              <TableHeadCell className="font-weight-500">{t('Full Name')}</TableHeadCell>
              <TableHeadCell className="font-weight-500">{t('Student ID')}</TableHeadCell>
              <TableHeadCell className="font-weight-500">{t('Grade Section')}</TableHeadCell>
              <TableHeadCell className="font-weight-500">{t('Email')}</TableHeadCell>
              <TableHeadCell></TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map(({ id, person, schoolEnrollment, academicYearEnrollment }) => (
              <TableRow
                key={id}
                hover
                onClick={() => openDetailPage({ id, type: membershipType })}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell sx={{ cursor: 'pointer', width: '4em', py: '0.6em' }}>
                  <Avatar
                    sx={{
                      '&:hover': { transform: 'scale(2)', zIndex: 100 },
                      transition: 'transform 0.2s ease-in-out',
                      transform: 'scale(1)',
                      width: 70,
                      height: 70,
                    }}
                    src={person?.profilePicture?.location}
                    {...CustomAvatar(`${person.firstName} ${person.astName}`)}
                  >
                    {[person.firstName?.[0], person.lastName?.[0]].join('')}
                  </Avatar>
                </TableCell>
                <TableCell>
                  {person?.firstName} {person?.middleName ?? ''} {person?.lastName}
                </TableCell>
                <TableCell>{schoolEnrollment.enrollmentId}</TableCell>
                <TableCell>
                  {academicYearEnrollment.gradeLevelId} {t('Section')} {academicYearEnrollment.gradeSection}
                </TableCell>
                <TableCell>{person.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count || 0}
          page={query?.limit ? query?.offset / query?.limit : 0}
          onPageChange={handleChangePage}
          rowsPerPage={query?.limit || 10}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </TableContainer>
    </Fragment>
  )
}

export default StudentList
