import { useParams } from 'react-router-dom'
import StudentList from './StudentList'
import StaffList from './StaffList'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguages } from 'store/lookup'
import { useEffect } from 'react'

export default function ContactsListIndex() {
  const { memberId } = useParams()
  const dispatch = useDispatch()

  const member = useSelector(state => state.member.list?.find(m => m.id === memberId))

  const languages = useSelector(state => state.lookup.languages)


  useEffect(() => {
    languages || dispatch(getLanguages())
  }, [dispatch])

  if (!languages) return null
  if (member.person.type === 'student') {
    return <StudentList data={member.contacts} />
  }
  if (member.person.type === 'staff') {
    return <StaffList data={member.contacts} />
  }
}
