import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getPending } from 'store/leave/leave-request'
import { BasePathProvider, useBasePath } from 'components/Providers'
import LeaveApprovalForm from './Form'
import LeaveApprovalFormSkeleton from './FormSkeleton'

export default function LeaveApprovalsFormIndex() {
  const dispatch = useDispatch()
  const [pendingApproval, setPendingApproval] = useState(null)
  const basePath = useBasePath()

  const { id } = useParams()

  useEffect(() => {
    if ( id && id !== 'new') {
      dispatch(getPending({ id })).then(res => {
        setPendingApproval(res.payload)
      })
    } else {
        setPendingApproval(null)
    }
  }, [dispatch, id])

  console.log(id)
  console.log(pendingApproval)

  if ( pendingApproval ) {
    return (
      <BasePathProvider value={`${basePath}`}>
        <LeaveApprovalForm pendingApproval={pendingApproval} />
      </BasePathProvider>
    );
  }

  if (!pendingApproval) {
    return < LeaveApprovalFormSkeleton />
  }
}
