import { useMember } from 'components/Providers'
import { Box, Chip, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { getGradeLevels, getRaceTypes } from 'store/lookup'
import { useEffect, useState } from 'react'
import { search as getMemberContacts } from 'store/member/contact'
import PreviewCard from 'components/PreviewCard'
import FeildSkeleton from '../Contact/List/FieldSkeleton'
import { useTranslation } from 'react-i18next'
import { useBasePath } from 'components/Providers'
import NotFound from 'components/NotFound'
import 'utils/global.scss'
import { search as getHealthConditions, clearConditions } from 'store/nurse/nurse-health-condition'
import { getNurseConditionTypes } from 'store/lookup'
import MedicationIcon from '@mui/icons-material/Medication';

const genders = ['Male', 'Female', 'Non-Binary/Undesignated']

const ethnicities = ['Hispanic or Latino', 'Not Hispanic or Latino']

export default function MemberProfile() {
  const member = useMember()
  const dispatch = useDispatch()
  const memberId = member?.id
  const membershipType = member?.person?.type
  
  const theme = useSelector(state => state.session.appearance)
  const raceTypes = useSelector(state => state.lookup.raceTypes)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const [notFound, setNotFound] = useState(false)
  const { t } = useTranslation()
  const basePath = useBasePath()
  const healthConditions = useSelector(state => state.nurse.healthCondition.list)
  const types = useSelector(state => state.lookup.nurseConditionType)
  const studentId = member?.person?.id

  useEffect(() => {
    const fetchData = async () => {
      const [raceTypesRes, gradeLevelsRes, memberContactsRes] = await Promise.all([
        !raceTypes && dispatch(getRaceTypes()),
        !gradeLevels && dispatch(getGradeLevels()),
        memberId && !member?.contacts && dispatch(getMemberContacts({ type: membershipType, memberId })),
      ])
      if (raceTypesRes?.error || gradeLevelsRes?.error || memberContactsRes?.error) {
        setNotFound(true)
      }
    }

    fetchData()
  }, [dispatch, memberId, member, membershipType])

  useEffect(() => {
    if (membershipType === "student" && studentId) {
      dispatch(getHealthConditions({ studentId }));
    }
  }, [dispatch, membershipType, studentId]);

  useEffect(() => {
    dispatch(clearConditions());
  }, [dispatch, membershipType]);

  useEffect(() => {
    types || dispatch(getNurseConditionTypes())
  }, [dispatch, Boolean (types)])

  const isReadyToGo = member && raceTypes && gradeLevels && member.contacts

  const sped = member.stateData?.sped
  const ell = member.stateData?.ell?.status
  const section504 = member.stateData?.section504
  const health = member.stateData?.healthRelatedInfo?.healthInsuranceStatus
  const restrain = member.contacts?.find(item => item.isGuardian === true && item.pickup === false)

  if (!isReadyToGo && !notFound) return null

  let races = ''
  if (Array.isArray(member.person?.raceTypeIds)) {
    member.person?.raceTypeIds.forEach(item => {
      const race = raceTypes?.find(rt => rt.id === item)
      if (race) {
        races = `${races} ${race.name},`
      }
    })
  }

  const healthConditionTooltip = () => {
    return (
      <Tooltip
      title={
        <Box>
          <Typography variant='body2' sx={{ fontWeight: 600, textAlign: 'center' }}>Health Conditions</Typography>
                <Table size="small">
                  <TableHead >
                    <TableRow>
                      <TableCell sx={{ color: 'primary.contrastText' }}>Condition</TableCell>
                      <TableCell sx={{color: 'primary.contrastText' }} >Explanation</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {healthConditions
                    .filter(condition => condition.visibleToStaff === true)
                    .map((condition, index) => (
                    <TableRow hover key={index} >
                      <TableCell sx={{color: 'primary.contrastText' }} >{types?.find(type => type.id === condition.conditionTypeId)?.name}</TableCell>
                      <TableCell sx={{color: 'primary.contrastText' }} >{condition.explanation}</TableCell>
                    </TableRow>
                ))}
                  </TableBody>
                </Table>
        </Box>
      }
    >
     <MedicationIcon color='primary' fontSize='medium' />
    </Tooltip>
    )
  }

  const profileData = [
    {
      key: 'Full Name',
      value: `${member?.person?.firstName} ${member?.person?.middleName ?? ''} ${member?.person?.lastName}` || '',
    },
    { key: 'State ID', value: member.stateData?.stateId || '' },
    {
      key: 'Date of Birth',
      value: member?.person?.dateOfBirth ? dayjs(member?.person?.dateOfBirth).format('MM-DD-YYYY') : '',
    },
    { key: 'Email/Username', value: member.person?.email || '' },

    {
      key: 'Gender',
      value: member?.person?.gender ? genders.find(g => g.toLowerCase() === member?.person?.gender.toLowerCase()) : '',
    },
    {
      key: 'Ethnicity',
      value: member?.person?.ethnicity
        ? ethnicities.find(e => e.toLowerCase() === member?.person?.ethnicity.replaceAll(/-/g, ' ').toLowerCase())
        : '',
    },
    { key: 'Race', value: races || '' },
    {
      key: 'Address',
      value: `${member.person?.addresses?.[0]?.street || ''} ${member.person?.addresses?.[0]?.city || ''} ${
        member.person?.addresses?.[0]?.state || ''
      } ${member.person?.addresses?.[0]?.zip || ''}`,
    },
  ]


  const staffProfileData = [
    { key: 'Full Name', value: `${member?.person?.firstName} ${member?.person?.middleName ?? ''} ${member?.person?.lastName}` || '', },
    { key: 'State ID', value: member?.person?.stateId || '' },
    { key: 'Gender', value: member?.person?.gender ? genders.find(g => g.toLowerCase() === member?.person?.gender.toLowerCase()) : '',},
    { key: 'School Email', value: member.person?.email || '' },
    { key: 'Personal Email', value: member.person?.email || '' },
    { key: 'School Extension', value: member.person?.phones?.find(phone => phone.type === 'work')?.number || ' ' },
    { key: 'Home Phone', value: member.person?.phones?.find(phone => phone.type === 'home')?.number || ' ' },
    { key: 'Cell Phone', value: member.person?.phones?.find(phone => phone.type === 'mobile')?.number || ' ' },
    { key: 'Address',
      value: `${member.person?.addresses?.[0]?.street || ''} ${member.person?.addresses?.[0]?.city || ''} ${
        member.person?.addresses?.[0]?.state || ''
      } ${member.person?.addresses?.[0]?.zip || ''}`,},
  ]

  if (!(raceTypes && gradeLevels)) return null

  if (notFound) return <NotFound basePath={`${basePath}`} />

  return (
    <Grid container>
      <Grid item xs={12} md={12} sx={{ height: '100%', mx: 3, mt: 3 }}>
        <PreviewCard
          title={t('Personal Information')}
          size="large"
          buttonNavigate={`/member/${member.person?.type}/${member.id}/person`}
          buttonLabel={t('More')}
          actionChildren={
              <Box>
                {sped && <Chip label="SPED" sx={{ mr: 1 }} />}
                {section504 && <Chip label="504" sx={{ mr: 1 }} />}
                {ell && <Chip label="ELL" sx={{ mr: 1 }} />}
                {health && <Chip label="Health" sx={{ mr: 1 }} />}
                {restrain && <Chip label="RO" sx={{ mr: 1 }} />}
                {healthConditions?.some(condition => condition.visibleToStaff === true) && healthConditionTooltip()}
              </Box>
          }
        >
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              {membershipType === "student" ? (
                profileData.map((item, index) => (
                <Grid key={index} item sm={4} xs={4} md={4}>
                  <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                    {t(item.key)}
                  </Typography>
                  <Typography variant="body1" className={`grid-table-title-value`}>
                    {item.value ? item.value : <FeildSkeleton />} &nbsp;
                  </Typography>
                </Grid>
              ))
            ) : (
              staffProfileData.map((item, index) => (
                <Grid key={index} item sm={4} xs={4} md={4}>
                  <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                    {t(item.key)}
                  </Typography>
                  <Typography variant="body1" className={`grid-table-title-value`}>
                    {item.value ? item.value : <FeildSkeleton />} &nbsp;
                  </Typography>
                </Grid>
              ))
            )}
            </Grid>
          </Grid>
        </PreviewCard>
      </Grid>
    </Grid>
  )
}
