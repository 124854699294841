import { Box, Skeleton, Stack } from '@mui/material';
import Header from 'components/Header';
import { BasePathProvider, useBasePath } from 'components/Providers';

export default function LeaveApprovalFormSkeleton() {
        const basePath = useBasePath()

    return (
        <BasePathProvider value={`${basePath}`}>
            <Box sx={{ overflowY: 'scroll', border: 'none' }}>
                <Header title={<Skeleton variant="rounded" width="100%" height={40} /> } small close borderBottom />
            </Box>
            <Box sx={{ overflowY: 'scroll', mt: 1, pl: 2, pr: 2}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', border: 'solid 1px rgba(0, 0, 0, 0.23)', p: 1, borderRadius: '5px' }}>
                    <Stack direction="column" spacing={0}>
                        <Skeleton variant="text" width="50%" height={40} />
                        <Skeleton variant="text" width="50%" height={40} />
                    </Stack>
                    <Stack direction="column" spacing={0}>
                        <Skeleton variant="text" width="50%" height={40} />
                        <Skeleton variant="text" width="50%" height={40} />
                    </Stack>
                    <Stack direction="column" spacing={0}>
                        <Skeleton variant="text" width="50%" height={40} />
                        <Skeleton variant="text" width="50%" height={40} />
                    </Stack>
                </Box>
            </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', px: 2, py: 1 }}>
                    <Skeleton variant="text" width="100%" height={40} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', px: 1, py: 2 }}>
                        <Skeleton variant="rounded" width="100px" height={40} />
                        <Skeleton variant="rounded" width="100px" height={40} />
                    </Box>
                </Box>
        </BasePathProvider>
    );
};
