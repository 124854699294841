import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { AddIcon, ExpandMoreIcon } from 'components/Icons'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import EnrollmentsListItem from './ListItem'

export default function EnrollmentsList({ campuses, activeCampuses, inactiveCampuses }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { personId } = useParams()
  const [expanded, setExpanded] = useState(undefined)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  if (!campuses) return null

  return (
    <Box>
      <Box sx={{ m: 3 }}>
        <Stack alignItems={'flex-end'} justifyContent={'flex-end'} sx={{ mb: 3 }} flexDirection={'row'} gap={1}>
          <Button
            startIcon={<AddIcon />}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => navigate(`/person/${personId}/campus/new`)}
          >
            {t('Assign Campus')}
          </Button>
        </Stack>

        {activeCampuses && activeCampuses.length >= 1 && (
          <Typography variant="h6" sx={{ my: 2, fontWeight: 'bold' }}>
            {t('Active Campuses')}
          </Typography>
        )}

        {activeCampuses &&
          activeCampuses.length >= 1 &&
          activeCampuses.map((item, index) => (
            <Accordion
              key={index}
              onChange={handleChange(`campus_${item?.id}`)}
              sx={{ boxShadow: 'none' }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container>
                  <Grid item sm={3} xs={3} md={3}>
                    <Typography sx={{ flexShrink: 0 }}>
                      {campuses.find(camp => camp.id == item?.campusId)?.name}
                    </Typography>
                  </Grid>
                  <Grid item sm={9} xs={9} md={9}>
                    <Typography sx={{ ml: 6 }}>Active since {dayjs(item?.startDate).format('L')}</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <EnrollmentsListItem data={item} campuses={campuses} />
              </AccordionDetails>
            </Accordion>
          ))}

        {activeCampuses && activeCampuses.length >= 1 && inactiveCampuses && inactiveCampuses.length >= 1 && (
          <Divider sx={{ mx: -3, mt: 5, mb: 3 }} />
        )}

        {inactiveCampuses && inactiveCampuses.length >= 1 && (
          <Typography variant="h6" sx={{ my: 2, fontWeight: 'bold' }}>
            {t('Inactive Campuses')}
          </Typography>
        )}

        {inactiveCampuses &&
          inactiveCampuses.length >= 1 &&
          inactiveCampuses.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === `campus_${item.id}`}
              onChange={handleChange(`campus_${item.id}`)}
              sx={{ boxShadow: 'none' }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container>
                  <Grid item sm={3} xs={3} md={3}>
                    <Typography sx={{ flexShrink: 0 }}>
                      {campuses.find(camp => camp.id == item?.campusId)?.name}
                    </Typography>
                  </Grid>
                  <Grid item sm={9} xs={9} md={9}>
                    <Typography sx={{ ml: 6 }}>Deactivated on {dayjs(item?.endDate).format('L')}</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <EnrollmentsListItem data={item} campuses={campuses} />
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
    </Box>
  )
}
