import { combineReducers } from '@reduxjs/toolkit'

import leaveRequestReducer from './leave-request'
import leaveRequestTypeReducer from './leave-request-type'
import leaveRequestRolloverReducer from './leave-request-rollover'

const combinedReducer = combineReducers({
  leaveRequest: leaveRequestReducer,
  leaveRequestType: leaveRequestTypeReducer,
  leaveRequestRollover: leaveRequestRolloverReducer,
})

export default combinedReducer