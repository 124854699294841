import { Grid, MenuItem, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default function ELLForm({ control, noAndYesOption, ellStatusOption, languageInsEduPrgOption, getValues }) {
  const { t } = useTranslation()

  if (!(control && noAndYesOption && ellStatusOption && languageInsEduPrgOption)) return null
  return (
    <>
      <Grid item sm={12} xs={12}>
        <Controller
          name="ell.status"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('ELL Status')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {ellStatusOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Controller
          name="ell.titleIIIEllStatus"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Title III ELL Status')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Controller
          name="ell.programStartDate"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t('ELL Program Start Date')}
                error={!!fieldState.error}
                size="small"
                value={value ? dayjs(value) : null}
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Controller
          name="ell.programEndDate"
          control={control}
          
          render={({ field: { value, ...field }, fieldState }) => {
            const parsedValue = value ? dayjs(value) : null;

            return (
              <DatePicker
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                label={t('ELL Program End Date')}
                error={!!fieldState.error}
                size="small"
                value={parsedValue}
                minDate={getValues()?.ell?.programStartDate ? dayjs(getValues()?.ell?.programStartDate) : null}
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Controller
          name="ell.exemptTakingElaPortion"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('ELL Exempt from Taking ELA portion')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Controller
          name="ell.njEllInfo"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('NJ ELL Information')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Controller
          name="ell.accommodation"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('ELL Accommodation')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {noAndYesOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Controller
          name="ell.languageInsEduPrg"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                label={t('Language Instruction Educational Program')}
                error={!!fieldState.error}
                size="small"
                fullWidth
                value={value || ''}
                {...field}
              >
                {languageInsEduPrgOption.map(item => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </Grid>
    </>
  )
}
