import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { ROWS_PER_PAGE_OPTIONS } from 'constants'
import _ from 'lodash'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/provider/course'

export default function CourseList({ selectedCourse, setSelectedCourse }) {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { query, list, count } = useSelector(state => state.provider.course)
  const listByDistrict = useSelector(state => state.settings.course.list)

  const filterNullValues = obj => {
    let temp = {}
    Object.keys(obj).forEach(key => {
      obj[key] !== null ? (temp[key] = obj[key]) : null
    })
    return temp
  }

  const handleChangePage = (event, page) => {
    const limit = query?.limit || 10
    dispatch(search({ ...filterNullValues(query), limit, offset: limit * page }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(search({ ...filterNullValues(query), offset: 0, limit }))
  }

  const rowsPerPageOptions = _.uniq([query?.limit, ...ROWS_PER_PAGE_OPTIONS]);

  if (!(list && listByDistrict)) return null

  return (
    <Fragment>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('SCED Code')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important' }}>{t('Course Name')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, index) => (
              <TableRow hover key={index}>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      onClick={() => setSelectedCourse(item.id)}
                      checked={
                        item.id === selectedCourse || listByDistrict.find(l => l.parentId === item.id) ? true : false
                      }
                      size="small"
                      color="primary"
                      sx={{ mr: 1 }}
                      disabled={listByDistrict.find(l => l.parentId === item.id)}
                    />
                    {item?.code}
                  </Box>
                </TableCell>
                <TableCell>{item?.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count || 0}
          page={query?.limit ? query?.offset / query?.limit : 0}
          onPageChange={handleChangePage}
          rowsPerPage={query?.limit || 10}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </TableContainer>
    </Fragment>
  )
}
