import { Badge, Box, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { searchPendingApprovals } from 'store/leave/leave-request'
import 'utils/global.scss'

const LEAVEREQUESTS = 0
const LEAVEAPPROVALS = 1
const PUNCHRECORDS = 2
const PUNCHAPPROVALS = 3

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = ['leaveRequest', 'leave-approvals', 'punch-records', 'punch-approvals'].indexOf(tabName)
  return i < 0 ? 0 : i
}


export default function SchedulingTabs() {
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const { '*': tabName } = useParams()
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
  const dispatch = useDispatch()

  const pendingCount = useSelector(state => state.leave.leaveRequest.pendingCount);
  const pendingApprovals = useSelector(state => state.leave.pendingApprovals);

  useEffect(() => {
    if (pendingCount === null || (pendingApprovals && pendingApprovals.length === 0)) {
        dispatch(searchPendingApprovals());
    }
}, [dispatch, Boolean(pendingCount), Boolean(pendingApprovals)]);

  useEffect(() => {
    setTabIndex(getTabIndex(tabName))
  }, [tabName])

  const basePath = `/settings/leave-request`

  return (
      <Box sx={{ borderBottom: 1, borderColor: 'divider', displayPrint: 'none' }}>
        <Tabs
          value={tabIndex}
          TabIndicatorProps={{ style: { background: 'primary.main', fontFamily: 'Roboto, sans-serif' } }}
        >
          <Tab
            sx={{ color: tabIndex === 0 && 'primary.main' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('LEAVE REQUESTS')}
            value={LEAVEREQUESTS}
            component={Link}
            to={`${basePath}/`}
          />
          <Tab
            sx={{ color: tabIndex === 1 && 'primary.main' }}
            className={tabIndex === 1 ? `tab-item-selected` : `tab-item-${theme}`}
            label={<Box>
              {t('LEAVE APPROVALS')}
              <Badge badgeContent={pendingCount} color="primary" sx={{ ml: '15px' }} />
            </Box>}
            value={LEAVEAPPROVALS}
            component={Link}
            to={`${basePath}/leave-approvals`}
          />
          <Tab
            sx={{ color: tabIndex === 2 && 'primary.main' }}
            className={tabIndex === 2 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('PUNCH RECORDS')}
            value={PUNCHRECORDS}
            component={Link}
            to={`${basePath}/punch-records`}
          />
          <Tab
            sx={{ color: tabIndex === 3 && 'primary.main' }}
            className={tabIndex === 3 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('PUNCH APPROVALS')}
            value={PUNCHAPPROVALS}
            component={Link}
            to={`${basePath}/punch-approvals`}
          />
        </Tabs>
      </Box>
 )
}
