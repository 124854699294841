import { useMember , useBasePath} from 'components/Providers'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import * as action from 'store/member/final-grades'
import { search } from 'store/settings/grading-scale'
import FinalGrades from 'components/FinalGrades'
import NotFound from 'components/NotFound'
import Skeleton from './Skeleton'

// TODO: sort course session by name

export default function Index() {
  const member = useMember()
  const dispatch = useDispatch()
  const basePath = useBasePath()

  const gradingScales = useSelector(state => state.settings.gradingScale.list)
  const academicYearId = useSelector(state => state.session.academicYear.id)
  const [notFound, setNotFound] = useState(false)

  const courseSessionEnrollmentGrades = useSelector(state => state.member.list?.find(item => item.id === member.id))
    .finalGrades?.data

  const gpas = useSelector(state => state.member.list?.find(item => item.id === member.id)).finalGrades?.gpas

  const markingPeriods = useSelector(state => state.session.academicYear.markingPeriods)

  const permission = useSelector(state => state.session.permissions)
  const hasCourseSessionViewPermission = parseInt(permission['setting.course-session'])

  useEffect(() => {
    dispatch(action.retrieve({ type: member.person?.type, memberId: member.id })).then(res => {
      res.error && setNotFound(true)
    })
  }, [dispatch, member.id, member.person?.type])

  useEffect(() => {
    gradingScales ||
      dispatch(search()).then(res => {
        res.error && setNotFound(true)
      })
  }, [gradingScales, academicYearId])

  if (notFound) return <NotFound basePath={`${basePath}/final-grades`} />

  if (!(courseSessionEnrollmentGrades && markingPeriods && gradingScales)) {
    return <Skeleton />
  }

  return (
    <FinalGrades
      courseSessionEnrollmentGrades={courseSessionEnrollmentGrades}
      markingPeriods={markingPeriods}
      hasCourseSessionViewPermission={hasCourseSessionViewPermission}
      gpas={gpas}
      gradingScales={gradingScales}
    />
  )
}
