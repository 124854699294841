import { Box, Paper, Typography, IconButton, Tooltip, Stack } from '@mui/material'
import { DeleteIcon, EditIcon } from 'components/Icons'
import { Link, NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { search, destroy } from 'store/leave/leave-request'
import dayjs from 'dayjs'
import { useRef, useState, useEffect } from 'react'
import { enqueueSnackbar } from 'notistack'
import Confirm from 'components/Dialog'
import { setSearchLimit, setSearchOffset, setSearchQuery, get } from 'store/leave/leave-request'
import { search as searchRollover } from 'store/leave/leave-request-rollover'
import _ from 'lodash'
import { ROWS_PER_PAGE_OPTIONS } from 'constants'
import moment from 'moment';

let id = 0
const handleUploadClick = (rowData, fileInputRef) => {
  id = rowData.id
  fileInputRef.current.click()
}

const handleFile = async (event, dispatch) => {
  const file = event.target.files[0]

  const formData = new FormData()
  formData.append('afile', file)
  formData.append('fileUploadedDate', dayjs().format())
  formData.append('id', id)
  const url = `/api/leave/leave-request/upload`
  const res = await window.fetch(url, { method: 'POST', body: formData })
  if (res.status !== 200) {
    enqueueSnackbar(res.statusText, { variant: 'error' })
  } else {
   await dispatch(get({ id }))
    
  }
}

export default function RequestLeaveList({ leaveRequestList, selectedTeacher, academicYear, leaveRequestType }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const [confirm, setConfirm] = useState(false)
  const fileInputRef = useRef(null)
  const leave_request = useSelector(state => state.leave.leaveRequest)
  const sessionAcademicYear = useSelector(state => state.session.academicYear)
  const sessionDistrict = useSelector(state => state.session.district)
  const { rows, query, count } = {
    rows: leave_request ? leave_request.list : [],
    query: leave_request ? leave_request.query : [],
    count: leave_request ? leave_request?.count : 0,
  }

  const onConfirm = async () => {
    setConfirm(false)
    await dispatch(destroy({ id }))

    await dispatch(
      searchRollover({
        personId: selectedTeacher?.personId,
        academicYearProviderId: sessionAcademicYear.markingPeriodProvider?.academicYearId,
      }),
    )
  }

  const fileCellRenderer = (params) => {
    const { fileId, file } = params.row;

    const handleFileClick = (event) => {
      event.preventDefault();
      window.open(file?.location, '_blank'); 
    };

    const tooltipTitle = () => {
      return (
        <Box sx={{gap: '20px'}}>              
        <Stack spacing={0}>
        <Typography sx={{ fontSize: '12px', fontWeight: 700}}>{t('File Name')}</Typography>
        <Typography sx={{ fontSize: '12px' }}>
          {file.name}
        </Typography>
        </Stack>
        <Stack spacing={0}>
        <Typography sx={{fontSize: '12px', fontWeight: 700}}>{t('Upload Date')}</Typography>
        <Typography sx={{ fontSize: '12px' }}>
          {moment(file.createdAt).format('MM/DD/YYYY hh:mm A')}
        </Typography>
        </Stack>
        </Box>
      )
    }

    return (
      <Box marginTop="15px">
        {fileId ? (
          <Tooltip 
            title={tooltipTitle()}
            placement="left"
            arrow
          >
          <Box display="flex" alignItems="center">
            <Link href="#" onClick={handleFileClick} target="_blank" sx={{ color: 'primary.main', underline: 'none', }}>
              <Typography sx={{ color: 'primary.main', textDecoration: 'none' }}>
                {t('View/Download')}
              </Typography>
            </Link>
          </Box>
          </Tooltip>
        ) : (
          <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => handleUploadClick(params.row, fileInputRef)}
        >
          <UploadFileIcon sx={{ color: 'primary.main', fontSize: '20px', marginRight: '5px' }} />
          <Typography sx={{ color: 'primary.main' }}>{t('Upload')}</Typography>
        </div>
        )}
        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={(e) => handleFile(e, dispatch)} />
  
      </Box>
    )
  }

  const handleSortModelChange = params => {
    if (params.length > 0) {
      const sortBy = params[0]?.field
      const sortOrder = params[0]?.sort
      dispatch(setSearchQuery({ ...query, sortBy, sortOrder }))
    }
  }

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  })

  const onPaginationModelChange = params => {
    if (params.pageSize !== paginationModel?.pageSize) {
      dispatch(setSearchLimit(params.pageSize))
      dispatch(setSearchOffset(0))
      setPaginationModel({ ...paginationModel, ...params, page: 0 })
    } else {
      dispatch(setSearchLimit(params.pageSize))
      dispatch(setSearchOffset(params.pageSize * params.page))
      setPaginationModel({ ...paginationModel, ...params })
    }
  }

  const filterNullValues = obj => {
    let temp = {}
    Object.keys(obj).forEach(key => {
      obj[key] !== null && obj[key] !== '' ? (temp[key] = obj[key]) : null
    })
    return temp
  }

  useEffect(
    () => {
      const personId = selectedTeacher?.personId
      if (personId) {
        const filters = {
          personId,
          academicYearProviderId: academicYear && academicYear !== 'All' ? academicYear : null,
          typeId: leaveRequestType && leaveRequestType !== 'All' ? leaveRequestType : null,
        }

        dispatch(search({ ...filterNullValues(query), ...filterNullValues(filters) }))
      }
    },
    [query?.limit, query?.offset, query?.sortBy, query?.sortOrder, selectedTeacher?.personId],
    academicYear,
    leaveRequestType,
  )

  const tableColumns = [
    {
      field: 'academicYear',
      headerName: t('Academic Year'),
      renderCell: params => params.row.academicYearProvider?.name || ' ',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'leaveType',
      headerName: t('Leave Type'),
      renderCell: params => params.row.leaveRequestType?.name,
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'leaveDate',
      headerName: t('Leave Date'),
      renderCell: params => params.row.leaveDate,
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'note',
      headerName: t('Notes'),
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'document',
      headerName: t('Document'),
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: fileCellRenderer
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => params.row.leaveRequestApprovalType?.name || ' ',
    },
    {
      field: 'requestedDate',
      headerName: t('Created'),
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: params => dayjs(params.row.requestedDate).tz(sessionDistrict.timeZone).format('M/D/YYYY h:mm A'),
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: params => (
        <Box>
          <IconButton component={NavLink} to={`${basePath}/${params.row.id}`}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              id = params.row.id
              setConfirm(true)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ]

  const pageSizeOptions = _.uniq([query?.limit, ...ROWS_PER_PAGE_OPTIONS])

  if (!rows) return null
  return (
    <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
      <Box sx={{ pt: 1 }}>
        <DataGrid
          // onCellClick={handleCellClick}
          rows={leaveRequestList}
          columns={tableColumns}
          rowCount={count}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          onSortModelChange={handleSortModelChange}
          pageSizeOptions={pageSizeOptions}
          page={query.offset / query.limit}
          pagination
          disableColumnMenu
          disableRowSelectionOnClick
          rowHeight={60}
          sx={{ height: query.limit * 61 + 100 }}
          slotProps={{
            row: {
              style: { cursor: 'pointer' },
            },
          }}
        />
        <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
      </Box>
    </Paper>
  )
}
