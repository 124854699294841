import { Box, Grid, Typography } from '@mui/material'
import PreviewCard from 'components/PreviewCard'
import { useMember } from 'components/Providers'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import 'utils/global.scss'

export default function EnrollmentsList() {
  const member = useMember()
  const theme = useSelector(state => state.session.appearance)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const { t } = useTranslation()

  return (
    <Box sx={{ m: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <PreviewCard
            title={t('Enrollment Information')}
            size="large"
            buttonLabel="More"
            buttonNavigate={`/member/${member.person.type}/${member.id}/enrollments/details`}
          >
            <Grid container spacing={1}>
              <Grid item sm={12} xs={12} md={12}>
                <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                  {t('Enroll Date')}
                </Typography>
                <Typography variant="body1" className={`grid-table-title-value`}>
                  {member?.schoolEnrollment?.startDate &&
                    dayjs(member?.schoolEnrollment?.startDate).format('MM/DD/YYYY')}
                  &nbsp;
                </Typography>
              </Grid>

              <Grid item sm={12} xs={12} md={12}>
                <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                  {t('Enroll Grade Level')}
                </Typography>
                <Typography variant="body1" className={`grid-table-title-value`}>
                  {
                    gradeLevels.find(item => item.id === member?.schoolEnrollment?.stateData?.enroll?.gradeLevelId)
                      ?.name
                  }
                  &nbsp;
                </Typography>
              </Grid>

              <Grid item sm={12} xs={12} md={12}>
                <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                  {t('Previous School')}
                </Typography>
                <Typography variant="body1" className={`grid-table-title-value`}>
                  {member.schoolEnrollment?.stateData?.enroll?.previousSchool}
                  &nbsp;
                </Typography>
              </Grid>

              <Grid item sm={12} xs={12} md={12}>
                <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                  {t('Enroll Comment')}
                </Typography>
                <Typography variant="body1" className={`grid-table-title-value`}>
                  {member.schoolEnrollment?.stateData?.enroll?.comment}
                  &nbsp;
                </Typography>
              </Grid>

              <Grid item xs={4} md={4}>
                &nbsp;
              </Grid>
              <Grid item xs={8} md={8}>
                &nbsp;
              </Grid>
            </Grid>
          </PreviewCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <PreviewCard
            title={t('Unenrollment Information')}
            size="large"
            buttonLabel={member.schoolEnrollment?.endDate ? 'More' : 'Unenroll Now!'}
            buttonNavigate={`/member/${member.person.type}/${member.id}/enrollments/details?unenroll=true`}
            buttonColor={member.schoolEnrollment?.endDate ? 'primary' : 'error'}
          >
            {member.schoolEnrollment?.endDate && (
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12} md={12}>
                  <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                    {t('Leave Grade Level')}
                  </Typography>
                  <Typography variant="body1" className={`grid-table-title-value`}>
                    {dayjs(member.schoolEnrollment?.endDate).format('MM/DD/YYYY')}
                    &nbsp;
                  </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={12}>
                  <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                    {t('Leave Date')}
                  </Typography>
                  <Typography variant="body1" className={`grid-table-title-value`}>
                    {member.schoolEnrollment.stateData?.unenroll?.leaveGradeLevel}
                    &nbsp;
                  </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={12}>
                  <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                    {t('Leave Code')}
                  </Typography>
                  <Typography variant="body1" className={`grid-table-title-value`}>
                    {member.schoolEnrollment.stateData?.unenroll?.leaveCode}
                    &nbsp;
                  </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={12}>
                  <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                    {t('Transferred School')}
                  </Typography>
                  <Typography variant="body1" className={`grid-table-title-value`}>
                    {member.schoolEnrollment.stateData?.unenroll?.transferredSchool}
                    &nbsp;
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} md={12}>
                  <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
                    {t('Leave Reason')}
                  </Typography>
                  <Typography variant="body1" className={`grid-table-title-value`}>
                    {member.schoolEnrollment.stateData?.unenroll?.leaveReason}
                    &nbsp;
                  </Typography>
                </Grid>
              </Grid>
            )}
          </PreviewCard>
        </Grid>
      </Grid>
    </Box>
  )
}
