import { useMember } from 'components/Providers'
import { useDispatch, useSelector } from 'react-redux'
import { getRelationshipTypes } from 'store/lookup'
import { retrieve as getContact } from 'store/member/contact'
import { useEffect, useLayoutEffect, useState } from 'react'
import StudentForm from './StudentForm'
import StaffForm from './StaffForm'
import NotFound from '../../component/FormNotFound'
import FormSkeleton from './FormSkeleton'

export default function ContactsFormIndex({ contactId }) {
  const dispatch = useDispatch()
  const [notFound, setNotFound] = useState(null)

  const member = useMember()
  const relationshipTypes = useSelector(state => state.lookup.relationshipTypes)

  const basePath = `/member/${member?.person?.type}/${member?.id}/contacts/${member.id}`
  useLayoutEffect(() => {
    if (!relationshipTypes) {
      dispatch(getRelationshipTypes()).then(res => {
        res.error && setNotFound(true)
      })
    }
  }, [])

  useEffect(() => {
    if (contactId !== 'new')
      dispatch(getContact({ type: member?.person?.type, memberId: member?.id, id: contactId }))
  }, [member?.id])

  if (notFound) return <NotFound basePath={`${basePath}`} title={'Parent/Guardian Information'} />

  if (!(member?.contacts && relationshipTypes)) return <FormSkeleton />
  if (member.person.type === 'student') {
    return <StudentForm data={member?.contacts?.find(item => item.id === +contactId)} />
  }
  if (member.person.type === 'staff') {
    return <StaffForm data={member?.contacts?.find(item => item.id === +contactId)} />
  }
}
