import { Box, Grid, MenuItem, Stack, TextField } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider, useMember, useBasePath } from 'components/Providers'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateButton, SubmitButton } from 'components/Buttons'
import { update as updateSchoolEnrollment } from 'store/member/school-enrollment'
import { useTranslation } from 'react-i18next'
import { ReplayIcon } from 'components/Icons'

const initialData = {
  startDate: '',
  endDate: '',
  stateData: {
    enroll: {
      gradeLevelId: '',
      previousSchool: '',
      comment: '',
    },
    unenroll: {
      leaveGradeLevel: '',
      leaveCode: '',
      transferredSchool: '',
      leaveReason: '',
    },
  },
}

const leaveCode = [
  {
    code: 'TC',
    description: 'Transfer to Charter School',
  },
]

export default function EnrollmentsForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const member = useMember()
  // const basePath = `/member/${member?.person?.type}/${member?.id}/enrollments`
  const basePath = useBasePath()
  const [searchParams] = useSearchParams()
  const unenrollParam = searchParams.get('unenroll')
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)

  const schema = yup.object().shape({
    startDate: yup.date().required('Required'),
    endDate: !unenrollParam ? yup.date().nullable() : yup.date().required('Required'),
  })

  const { control, handleSubmit, reset, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
  })

  const endDateWatch = useWatch({ control, name: 'endDate' })
  const startDateWatch = useWatch({ control, name: 'startDate' })

  useEffect(() => {
    let data = {}
    _.defaultsDeep(
      data,
      {
        ..._.pick(member?.schoolEnrollment, ['startDate', 'endDate', 'stateData']),
      } || {
        stateData: { enroll: null, unenroll: null },
      },
      initialData,
    )
    reset(data)

    setValue(
      'stateData.unenroll.leaveGradeLevel',
      member?.schoolEnrollment?.stateData?.unenroll?.leaveGradeLevel || member?.academicYearEnrollment?.gradeLevelId,
    )

    if (unenrollParam && getValues().endDate === null) {
      setValue('endDate', dayjs(new Date().now))
    }
  }, [member])

  const onSubmit = async formData => {
    let payload = formData
    if (payload.endDate) {
      payload.endDate = dayjs(new Date(payload.endDate)).format('YYYY-MM-DD')
    }
    payload.startDate = dayjs(new Date(payload.startDate)).format('YYYY-MM-DD')
    if (member.schoolEnrollment.id) {
      const res = await dispatch(
        updateSchoolEnrollment({
          type: member?.person?.type,
          memberId: member?.id,
          ...payload,
        }),
      )
      if (res.payload) {
        reset(res.payload)
        if (payload) navigate(basePath)
      } else {
        console.error(res.errors)
      }
    }
  }

  const onSubmitReEnroll = async () => {
    let payload = getValues()
    payload.endDate = null
    payload.stateData.unenroll = {}
    if (member.schoolEnrollment.id) {
      const res = await dispatch(
        updateSchoolEnrollment({
          type: member.person.type,
          memberId: member?.id,
          ...payload,
        }),
      )
      if (res.payload) {
        reset(res.payload)
        if (payload) navigate(basePath)
      } else {
        console.error(res.errors)
      }
    }
  }

  const onError = errors => console.error(errors)

  if (!(gradeLevels && member)) return null

  return (
    <BasePathProvider value={`${basePath}/enrollments`}>
      <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
        {!unenrollParam && (
          <Box>
            <Header small title={t('Edit Enrollment Information')} close borderBottom />
            <Box sx={{ m: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <DatePicker
                          sx={{ width: '100%' }}
                          slotProps={{ textField: { size: 'small' } }}
                          label={t('Enroll Date')}
                          error={!!fieldState.error}
                          size="small"
                          value={value ? dayjs(value) : null}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="stateData.enroll.gradeLevelId"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          select
                          label={t('Grade Level')}
                          error={!!fieldState.error}
                          size="small"
                          fullWidth
                          value={value || ''}
                          {...field}
                        >
                          {gradeLevels.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="stateData.enroll.previousSchool"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          label={t('Previous School')}
                          error={!!fieldState.error}
                          size="small"
                          fullWidth
                          value={value || ''}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="stateData.enroll.comment"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          label={t('Enroll Comment')}
                          error={!!fieldState.error}
                          size="small"
                          fullWidth
                          value={value || ''}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}

        {unenrollParam && (
          <Box>
            <Header small title={t('Edit Unenrollment Information')} close borderBottom />
            <Box sx={{ m: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <DatePicker
                          sx={{ width: '100%' }}
                          slotProps={{ textField: { size: 'small' } }}
                          label={t('Leave Date')}
                          error={!!fieldState.error}
                          size="small"
                          value={value ? dayjs(value) : ''}
                          minDate={dayjs(getValues().startDate)}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="stateData.unenroll.leaveGradeLevel"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          select
                          label={t('Leave GradeLevel')}
                          error={!!fieldState.error}
                          size="small"
                          fullWidth
                          value={
                            value ||
                            member?.schoolEnrollment?.stateData?.unenroll?.leaveGradeLevel ||
                            member?.academicYearEnrollment?.gradeLevelId ||
                            ''
                          }
                          {...field}
                        >
                          {gradeLevels.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="stateData.unenroll.leaveCode"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          select
                          label={t('Leave Code')}
                          error={!!fieldState.error}
                          size="small"
                          fullWidth
                          value={value || ''}
                          {...field}
                        >
                          {leaveCode.map(item => (
                            <MenuItem key={item.code} value={item.code}>
                              {item.code} - {item.description}
                            </MenuItem>
                          ))}
                        </TextField>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="stateData.unenroll.transferredSchool"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          label={t('Transferred School')}
                          error={!!fieldState.error}
                          size="small"
                          fullWidth
                          value={value || ''}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="stateData.unenroll.leaveReason"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <TextField
                          label={t('Leave Reason')}
                          error={!!fieldState.error}
                          size="small"
                          fullWidth
                          value={value || ''}
                          {...field}
                        />
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
        <Stack alignItems={'flex-end'} flexDirection={'row'} justifyContent={'flex-end'} sx={{ mt: 6, mr: 2 }}>
          {unenrollParam && member?.schoolEnrollment?.endDate && (
            <CreateButton size="small" title={t('Re-Enroll')} color="error" onClick={onSubmitReEnroll} sx={{ mr: 2 }} icon={<ReplayIcon />} />
          )}
          <SubmitButton
            size="small"
            color="primary"
            title={t('Save')}
            onClick={handleSubmit(onSubmit)}
            sx={{ mr: 1 }}
            disabled={(unenrollParam && endDateWatch === null) || startDateWatch === null}
          />
        </Stack>
      </Box>
    </BasePathProvider>
  )
}
