import { Button, Drawer, Grid, Typography } from "@mui/material";
import { BasePathProvider } from "components/Providers";
import { useTranslation } from 'react-i18next'
import LeaveApprovalsIndex from "./List";
import { useNavigate, useParams } from "react-router-dom";
import LeaveApprovalsFormIndex from "./Form";
import Confirm from "components/Dialog";
import { useState } from "react";

export default function LeaveApprovalsPage() {
  const basePath = `/settings/leave-request/leave-approvals`
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const [confirm, setConfirm] = useState(false) 

  const onConfirm = async () => {
    setConfirm(false)
    console.log('confirmed')
  }

  return (
  
    <BasePathProvider value={`${basePath}`}>
        <Grid container spacing={2} sx={{ p: 1 }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ textAlign: 'left', mt: '25px', fontSize: '20px' }}>{`${t('Leave Requests Awaiting Review')}`}</Typography>
                <Button variant="outlined" color="primary" sx={{ mt: 2 }} onClick={() => {setConfirm(true)}}>{t('Approve All')}</Button>
            </Grid>
            <LeaveApprovalsIndex />
            <Drawer 
                PaperProps={{
                    sx: {
                        width: 800,
                    },
                }}
                open={Boolean(id)}
                anchor='right'
                onClose={() => navigate(basePath)}
            >
                <LeaveApprovalsFormIndex />
            </Drawer>
            <Confirm 
                open={confirm} onClose={() => setConfirm(false)} 
                onConfirm={onConfirm} 
                dialogTitle={"Are You Sure?"} 
                message={"Please confirm you want to approve all pending requests!"} 
            />
        </Grid>

    </BasePathProvider>)
}