import { Grid, Typography } from '@mui/material'
import PreviewCard from 'components/PreviewCard'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export default function SPEDInfoCard({ member, onlyReadable }) {
  const { t } = useTranslation()
  return (
    <PreviewCard
      title={t('SPED Information')}
      size="large"
      buttonLabel="More"
      buttonNavigate={`/member/${member.person.type}/${member.id}/state-data/sped`}
      onlyReadable={onlyReadable}
    >
      <Grid container spacing={4}>
        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Eligibility Date')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.sped?.eligibilityDeterminationDate &&
              dayjs(member?.stateData?.sped?.eligibilityDeterminationDate).format('MM/DD/YYYY')}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Initial IEP Meeting Date')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.sped?.initialIEPMeetingDate &&
              dayjs(member?.stateData?.sped?.initialIEPMeetingDate).format('MM/DD/YYYY')}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Parental Consent')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.sped?.parentalConsentObtained}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Annual IEP Meeting Date')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.sped?.annualIEPReviewMeetingDate &&
              dayjs(member?.stateData?.sped?.annualIEPReviewMeetingDate).format('MM/DD/YYYY')}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Special Ed. Classification')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.sped?.specialEducationClassification}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Reevaluation Date')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.sped?.reevaluationDate &&
              dayjs(member?.stateData?.sped?.reevaluationDate).format('MM/DD/YYYY')}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Counseling Services')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.sped?.counselingServices}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Physical Therapy Services')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.sped?.physicalTherapyServices}
            &nbsp;
          </Typography>
        </Grid>

        <Grid item xs={4} md={4}>
          <Typography variant="body2">{t('Speech Therapy Services')}</Typography>
          <Typography variant="body1">
            {member?.stateData?.sped?.speechLanguageTherapyServices}
            &nbsp;
          </Typography>
        </Grid>
      </Grid>
    </PreviewCard>
  )
}
