import { Button, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { useBasePath } from "components/Providers";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { pendingSave, searchPendingApprovals } from "store/leave/leave-request";
import EllipsisTooltipCell from "components/ElipsisTooltipCell";

export default function LeaveApproalListItem({ item }){
    const basePath = useBasePath()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleDenyClick = () => {
        navigate(`${basePath}/${item.id}`);
    }

    const handleApproveClick = async () => {
        await dispatch(pendingSave({ id: item.id, approvalStatusId: "approved" }));
        await dispatch(searchPendingApprovals());
    }

    return (
        <TableRow hover>
            <TableCell width="20%">{item?.requester?.lastName}, {item?.requester?.firstName}</TableCell>
            <EllipsisTooltipCell width="20%" text={dayjs(item?.requestedDate).format('MM/DD/YYYY hh:mm A')} /> 
            <EllipsisTooltipCell width="20%" text={item?.leaveRequestType?.name} />
            <TableCell width="10%">{dayjs(item.leaveDate).format('MM/DD/YYYY')}</TableCell>
            <EllipsisTooltipCell width="20%" text={item?.note} />
            <TableCell width="10%">
                {item?.leaveRequestType?.name.includes('Professional Development') ? (
                    item?.file || <Typography variant="body2" color='error'>Not Uploaded</Typography>
                ) : (
                    item?.file || <Typography variant="body2">Not Required</Typography>
                )}
            </TableCell>
            <TableCell width="10%">
                <Stack direction="row" spacing={1}>
                    <Button variant="outlined" color="success" size="small" onClick={handleApproveClick}>
                        {'Approve'}
                    </Button>
                    <Button variant="outlined" color="error" size="small" onClick={handleDenyClick}>
                        {'Deny'}
                    </Button>
                </Stack>
            </TableCell>
        </TableRow>
    )
}