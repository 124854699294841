import { useDispatch, useSelector } from 'react-redux'
import { getRelationshipTypes } from 'store/lookup'
import { retrieve as getContact } from 'store/person/contact'
import { useEffect, useLayoutEffect } from 'react'
import ContactsForm from './Form'
import { useParams } from 'react-router-dom'

export default function ContactsFormIndex() {
  const dispatch = useDispatch()
  const { personId, contactId } = useParams()

  const relationshipTypes = useSelector(state => state.lookup.relationshipTypes)
  const contactDetail = useSelector(state => state.person2.contactDetails)

  useLayoutEffect(() => {
    if (!relationshipTypes) {
      dispatch(getRelationshipTypes())
    }
  }, [])

  useEffect(() => {
    if (contactId && contactId !== 'new') {
      dispatch(getContact({ personId, id: contactId }))
    }
  })

  if (contactId === 'new') return <ContactsForm />

  if (!(contactDetail && relationshipTypes)) return null

  if (contactDetail.id === +contactId) return <ContactsForm data={contactDetail} />
}
