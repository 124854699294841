import {
  TextField,
  IconButton,
  Grid,
  Box,
  MenuItem,
  InputAdornment,
  Chip,
  InputLabel,
  FormControl,
  Select,
  Autocomplete,
  Stack,
  Divider,
} from '@mui/material'
import Header from 'components/Header'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
/* import InviteIcon from '@mui/icons-material/Send'*/
import { SubmitButton } from 'components/Buttons'
import AddressList from './Address/AddressList'
import { getEthnicityTypes, getGenderTypes, getRaceTypes } from 'store/lookup'
import { save, create } from 'store/person/index'
import { search } from 'store/settings/job-title'
import { search as searchJobPosition } from 'store/settings/job-position'
import { search as searchDepartment } from 'store/settings/department'
import { search as searchEvaluation } from 'store/settings/evaluation'
import _ from 'lodash'
import { SaveSharp } from 'components/Icons'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { search as searchRole } from 'store/settings/role'
import FormSkeleton from './FormSkeleton'

// TODO: Birthday min max
// TODO: Form Skeleton (after design)

const titles = ['Mr.', 'Ms.', 'Mrs.']
const suffixes = ['Jr.', 'Sr.', 'II', 'III']
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const initialValues = {
  type: 'staff',
  firstName: '',
  lastName: '',
  middleName: null,
  suffix: null,
  gender: null,
  ethnicity: null,
  email: null,
  password: null,
  dateOfBirth: null,
  homePhone: null,
  cellPhone: null,
  ssn: null,
}
export default function PersonEditForm({ jobTitleData }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const basePath = useBasePath()
  const { personId } = useParams()

  const { raceTypes, ethnicityTypes, genderTypes } = useSelector(state => state.lookup)
  const member = personId === 'new' ? initialValues : useSelector(state => state.person2.details)
  const jobTitles = useSelector(state => state.settings.jobTitles?.list)
  const jobPositions = useSelector(state => state.settings.jobPosition?.list)
  const departments = useSelector(state => state.settings.departments?.list)
  const evaluations = useSelector(state => state.settings.evaluations?.list)
  const roles = useSelector(state => state.settings.role.search)

  const [allRoles, setAllRoles] = useState(roles || [])

  const [jobTitle, setJobTitle] = useState(jobTitleData?.jobTitle)

  const [jobPosition, setJobPosition] = useState(jobTitleData?.jobPosition)

  const [department, setDepartment] = useState(jobTitleData?.department)

  const [evaluation, setEvaluation] = useState(jobTitleData?.evaluation)

  // const [ssnVisibility, setSsnVisibility] = useState(false)
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const [open, setOpen] = useState(false)

  const [openRole, setOpenRole] = useState(false)

  const handleClickRaceType = () => {
    setOpen(true)
  }

  const schema = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .nullable(false)
        .required('Required')
        .transform(value => value.trim())
        .test('minLength', '', value => value.trim().length >= 1),
      lastName: yup
        .string()
        .nullable(false)
        .required('Required')
        .transform(value => value.trim())
        .test('minLength', '', value => value.trim().length >= 1),
      middleName: yup.string().nullable(),
      suffix: yup.string().nullable(),
      gender: yup.string().nullable(),
      ethnicity: yup.string().nullable(),
      email: yup
        .string()
        .email('Invalid email format')
        .nullable()
        .transform(value => value || null),
      password: yup
        .string()
        .nullable()
        .transform(value => value || null),
      dateOfBirth: yup.date().nullable(),
      jobTitleId: yup.number().nullable(),
      stateId: yup.number().typeError('State ID must be a number').nullable(),
      localId: yup.number().typeError('Local ID must be a number').nullable(),
      roomNumber: yup.string().trim().nullable(),
      phoneExtension: yup.string().trim().nullable(),
      user: yup.object().shape({
        email: yup
          .string()
          .email('Invalid email format')
          .nullable()
          .transform(value => value || null),
      }),
      phones: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string(),
            number: yup.string(),
          }),
        )
        .min(0),
      addresses: yup.array().of(
        yup.lazy((value, originalValue) => {
          if (originalValue.path === 'addresses[0]') {
            return yup.object().shape({
              type: yup.string().default('home').required('Type is required'),
              street: yup.string().required('Street is required'),
              city: yup.string().required('City is required'),
              state: yup.string().required('State is required'),
              country: yup.string().default('US'),
              zip: yup.string().required('ZIP is required'),
            })
          }
          return yup.object().shape({
            type: yup.string(),
            street: yup.string(),
            city: yup.string(),
            state: yup.string(),
            country: yup.string().default('US'),
            zip: yup.string(),
          })
        }),
      ),
    })
    .required()
  const handleDeleteRaceType = e => {
    const list = getValues()?.raceTypeIds?.filter(item => item !== e)
    setValue('raceTypeIds', [...list])
  }
  const handleClickRoleType = () => {
    setOpenRole(true)
  }
  const handleDeleteRoleType = e => {
    const list = getValues()?.roles?.filter(item => item !== e)
    setValue('roles', [...list])
  }
  const updateJobTitleData = jobTitleId => {
    const selectedJobTitle = jobTitles?.find(item => item.id === jobTitleId)
    setJobTitle(selectedJobTitle)

    if (selectedJobTitle) {
      setJobPosition(jobPositions?.find(item => item.id === selectedJobTitle.jobPositionId))
      setDepartment(departments?.find(item => item.id === selectedJobTitle.departmentId))
      setEvaluation(evaluations?.find(item => item.id === selectedJobTitle.evaluationId))
    }
  }
  useEffect(() => {
    if (roles?.length && member.roles?.length) {
      const memberRoles = member.roles.filter(item => !roles.some(role => role.id === item.id))
      setAllRoles([...roles, ...memberRoles])
    }
  }, [roles, member.roles])

  useEffect(() => {
    roles || dispatch(searchRole())
  }, [roles])

  useEffect(() => {
    raceTypes || dispatch(getRaceTypes())
  }, [raceTypes])

  useEffect(() => {
    ethnicityTypes || dispatch(getEthnicityTypes())
  }, [ethnicityTypes])

  useEffect(() => {
    genderTypes || dispatch(getGenderTypes())
  }, [genderTypes])

  useEffect(() => {
    updateJobTitleData(getValues('jobTitleId'))
    jobTitles || dispatch(search())
  }, [jobTitles])

  useEffect(() => {
    jobPositions || dispatch(searchJobPosition())
  }, [jobPositions])

  useEffect(() => {
    departments || dispatch(searchDepartment())
  }, [departments])

  useEffect(() => {
    evaluations || dispatch(searchEvaluation())
  }, [evaluations])

  const { control, handleSubmit, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialValues,
      ...member,
      roles: member?.roles?.map(role => role.id),
      homePhone: member?.phones?.find(p => p.type === 'home')?.number,
      cellPhone: member?.phones?.find(p => p.type === 'mobile')?.number,
    },
  })

  const addressArrayControl = useFieldArray({ control, name: 'addresses', keyName: 'uuid' })

  const onSubmit = async datas => {
    const phones = []
    member?.phones?.forEach(p => {
      if (p.type !== 'home' && p.type !== 'mobile') {
        phones.push(p)
      }
    })
    if (datas.homePhone) {
      const homePhone = member?.phones?.find(p => p.type === 'home')
      if (homePhone) {
        phones.push({ id: homePhone.id, type: 'home', number: datas.homePhone })
      } else {
        phones.push({ type: 'home', number: datas.homePhone })
      }
    }
    if (datas.cellPhone) {
      const cellPhone = member?.phones?.find(p => p.type === 'mobile')
      if (cellPhone) {
        phones.push({ id: cellPhone.id, type: 'mobile', number: datas.cellPhone })
      } else {
        phones.push({ type: 'mobile', number: datas.cellPhone })
      }
    }
    const addresses = []
    for (const address of datas.addresses) {
      if (!address.country) {
        address.country = 'US'
      }
      if (address.street.length > 0) {
        addresses.push(address)
      }
    }
    if (!datas?.type) {
      datas.type = 'staff'
    }
    const data = _.pick(datas, [
      'id',
      'type',
      'title',
      'firstName',
      'lastName',
      'middleName',
      'formerName',
      'suffix',
      'email',
      'gender',
      'ethnicity',
      'raceTypeIds',
      'dateOfBirth',
      'ssn',
      'phoneExtension',
      'localId',
      'stateId',
      'roomNumber',
    ])

    console.log('data', data)
    data.jobTitleId = jobTitle?.id

    if (datas.roles) {
      data.roles = datas.roles
        .map(roleId => {
          const item = roles.find(role => role.id === roleId)
          return item ? { id: item.id, name: item.name } : null
        })
        .filter(item => item !== null)
    }

    
    data.phones = phones
    data.addresses = addresses
    const res = personId === 'new' ? await dispatch(create(_.omit(data, ['id']))) : await dispatch(save(data))

    if (res.payload?.id === +personId) {
      navigate(`${basePath}${personId}/profile/`)
    }
    if (personId === 'new' && res?.payload?.id) {
      navigate(`${basePath}/${res?.payload?.id}/profile/`)
    }
  }
  const onErrors = errors => console.error(errors)

  if (!(member && raceTypes && ethnicityTypes && genderTypes && jobTitles)) return <FormSkeleton />
  return (
    <Box data-component="PersonFormForSP" component="form" onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
      <BasePathProvider value={personId === 'new' ? '/person/' : `${basePath}${personId}/profile/`}>
        <Header
          title={`${personId === 'new' ? t('New Person') : t('Personal Information')}`}
          small
          close
          borderBottom
        />

        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={4} md={4}>
            <Controller
              name="title"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <Autocomplete
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disablePortal
                    {...field}
                    size="small"
                    fullWidth
                    // freeSolo
                    clearIcon={null}
                    options={titles || []}
                    onChange={(event, value) => {
                      value ? setValue('title', value) : null
                    }}
                    onInputChange={(event, value, reason) => {
                      switch (reason) {
                        case 'reset':
                          break
                        case 'clear':
                          setValue('title', null)
                          break
                        case 'input':
                          setValue('title', value)
                          break
                        default:
                          break
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        {...field}
                        fullWidth
                        label={t('Title')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    required
                    size="small"
                    autoFocus
                    fullWidth
                    label={t('First Name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    required
                    size="small"
                    fullWidth
                    label={t('Last Name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="middleName"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('Middle Name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="formerName"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('Former Name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="suffix"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <Autocomplete
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disablePortal
                    {...field}
                    size="small"
                    fullWidth
                    freeSolo
                    options={suffixes || []}
                    onChange={(event, value) => {
                      value ? setValue('suffix', value) : null
                    }}
                    onInputChange={(event, value, reason) => {
                      switch (reason) {
                        case 'reset':
                          break
                        case 'clear':
                          setValue('suffix', null)
                          break
                        case 'input':
                          setValue('suffix', value)
                          break
                        default:
                          break
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        {...field}
                        fullWidth
                        label={t('Suffix')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="gender"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Gender')}
                    value={value || null}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    {genderTypes?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={8} md={8}>
            <Controller
              name="raceTypeIds"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-chip-label" sx={{ width: 300, mb: 1, top: '-7px' }}>
                      Race
                    </InputLabel>
                    <Select
                      open={open}
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      size="small"
                      multiple
                      label={t('Race')}
                      error={!!fieldState.error}
                      value={value || []}
                      {...field}
                      renderValue={selected => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected?.map(select => {
                            const found = raceTypes?.find(race => race.id === select)

                            return found ? (
                              <Chip
                                key={select}
                                variant="outlined"
                                label={`${found.name}`}
                                onClick={() => handleClickRaceType(select)}
                                onDelete={() => handleDeleteRaceType(select)}
                                onMouseDown={event => {
                                  event.stopPropagation()
                                }}
                              />
                            ) : null
                          })}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {raceTypes?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="ethnicity"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Ethnicity')}
                    value={value || null}
                    defaultValue={value || null}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    {ethnicityTypes?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="dateOfBirth"
              control={control}
              render={({ field, fieldState }) => {
                const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
                return (
                  <DatePicker
                    sx={datePickerSx}
                    slotProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                      textField: { size: 'small' },
                      field: {
                        fullWidth: true,
                        // margin: 'dense',
                        // variant: 'standard',
                        InputLabelProps: { shrink: true },
                      },
                    }}
                    label={t('Date of Birth')}
                    // margin='dense'
                    // variant='standard'
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    value={field.value && dayjs(field.value)}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="ssn"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('SSN')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                    //  type={ssnVisibility ? 'text' : 'password'}
                    /*                     InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setSsnVisibility(!ssnVisibility)}
                          >
                            {!ssnVisibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }} */
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <AddressList control={control} path="addresses" {...addressArrayControl} />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="homePhone"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('Home Phone')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="cellPhone"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('Cell Phone')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="email"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('Personal Email')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    /*                     InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <InviteIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }} */
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} sx={{ ml: -2 }}>
            <Header title={t('Work Related Information')} small />
          </Grid>
          <Grid item xs={12} md={12} sx={{ ml: -2, mt: -2, mb: 1 }}>
            <Divider style={{ width: '104%' }} />
          </Grid>
          <Grid item xs={8} md={8}>
            <Controller
              name="roles"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-chip-label" sx={{ width: 300, mb: 1, top: '-7px' }}>
                      User Role
                    </InputLabel>
                    <Select
                      open={openRole}
                      onClose={() => setOpenRole(false)}
                      onOpen={() => setOpenRole(true)}
                      size="small"
                      multiple
                      label={t('User Role')}
                      error={!!fieldState.error}
                      value={value || []}
                      {...field}
                      renderValue={selected => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected?.map(select => {
                            const found = allRoles?.find(role => role.id === select)
                            return found ? (
                              <Chip
                                key={select}
                                variant="outlined"
                                label={`${found.name}`}
                                onClick={() => handleClickRoleType(select)}
                                onDelete={() => handleDeleteRoleType(select)}
                                onMouseDown={event => {
                                  event.stopPropagation()
                                }}
                              />
                            ) : null
                          })}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {allRoles?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="jobTitleId"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Job Title')}
                    value={jobTitle?.id || value}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    onChange={e => updateJobTitleData(e.target.value)}
                  >
                    {jobTitles?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <TextField
              size="small"
              fullWidth
              disabled
              label={t('Job Position')}
              value={jobPosition?.name || jobTitleData?.jobPosition?.name}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <TextField
              size="small"
              fullWidth
              disabled
              label={t('Department')}
              value={department?.name || jobTitleData?.department?.name}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <TextField
              size="small"
              fullWidth
              disabled
              label={t('Evaluation')}
              value={evaluation?.name || jobTitleData?.evaluation?.name}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Controller
              name="stateId"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('State ID')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={4} md={4}>
            <Controller
              name="localId"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('Local ID')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={4} md={4}>
            <Controller
              name="roomNumber"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('Room Number')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={4} md={4}>
            <Controller
              name="phoneExtension"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('Phone Extension')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={4} md={4}>
            <Controller
              name="user.email"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    disabled
                    fullWidth
                    label={t('School Email')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={4} md={4}>
            <Controller
              name="password"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    disabled
                    label={t('Password')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    value={field.value || ''}
                    type={passwordVisibility ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setPasswordVisibility(!passwordVisibility)}
                          >
                            {!passwordVisibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )
              }}
            />
          </Grid>
        </Grid>

        <Stack flexDirection={'row'} justifyContent={'flex-end'} sx={{ mr: 2 }}>
          <SubmitButton
            onClick={handleSubmit(onSubmit, onErrors)}
            variant="contained"
            title={t('SAVE')}
            color="primary"
            icon={
              <Box sx={{ display: 'flex', alignItems: 'center', ml: -1 }}>
                <SaveSharp />
                <Box sx={{ ml: 1 }} />
              </Box>
            }
          />
        </Stack>
      </BasePathProvider>
    </Box>
  )
}
