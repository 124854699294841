import { Suspense, lazy, useState, useEffect, Fragment } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import Students from 'pages/Parent/Students'
import { useSession } from '../components/Providers'
import { useDispatch, useSelector } from 'react-redux'
import { getWidgets } from 'store/lookup'
import { destroyWidget } from 'store/session'
import { CloseIcon } from './Icons'

export default function WidgetLoader() {
  const dispatch = useDispatch()
  const widgets = useSelector(state => state.lookup.widgets)
  const [components, setComponents] = useState([])

  const {
    person: { type, id },
    district,
  } = useSession()

  const deleteWidget = id => {
    dispatch(destroyWidget({ id }))
    dispatch(getWidgets())
  }

  const loadComponent = async (componentName, widget) => {
    const Component = await lazy(() =>
      import(`components/Widgets/${componentName}`).catch(() => {
        return { default: () => <></> }
      }),
    )
    return { Component, data: widget }
  }

  useEffect(() => {
    const loadComponents = async () => {
      if (!widgets) {
        setComponents([])
        return
      }
      const componentPromises = widgets.map(widget => loadComponent(widget.widget.name, widget))
      const loadedComponents = await Promise.all(componentPromises)
      setComponents(loadedComponents)
    }
    loadComponents()
  }, [widgets])

  useEffect(() => {
    widgets || dispatch(getWidgets())
  }, [type, id, widgets])


  const getMDSize = name => {
    if (name === 'PercentCard') {
      return 12
    } else if (name === 'GaugeCharts') {
      return 12
    }
    return 12
  }

  const getLGSize = name => {
    if (name === 'PercentCard') {
      return 12
    } else if (name === 'GaugeCharts') {
      return 12
    }
    return 6
  }

  const getXLSize = name => {
    if (name === 'PercentCard') {
      return 12
    } else if (name === 'GaugeCharts') {
      return 12
    }
    return 6
  }

  return (
    <Fragment>
      {type === 'parent' ? (
        <Students />
      ) : (
        <Grid container spacing={2}>
          {components.map(({ Component, data }, index) =>
            data.widget.name === 'Clients' && district.isServiceProvider === false ? null : (
              <Grid
                item
                xs={12}
                md={getMDSize(data.widget.name)}
                lg={getLGSize(data.widget.name)}
                xl={getXLSize(data.widget.name)}
                key={index}
              >
                <Box sx={{ position: 'relative' }}>
                  <Suspense key={index} fallback={<div>Loading...</div>}>
                    <Component />
                  </Suspense>
                  {!data.providerId && (
                    <IconButton
                      sx={{
                        position: 'absolute',
                        right: -4,
                        top: -4,
                        mr: 0,
                        opacity: 0,
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          opacity: 1,
                          boxShadow: 'none',
                          backgroundColor: 'transparent',
                        },
                      }}
                      onClick={() => {
                        deleteWidget(data.id)
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            ),
          )}
        </Grid>
      )}
    </Fragment>
  )
}
