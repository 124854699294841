import { Box, Stack } from '@mui/material'
import StudentListItem from './StudentListItem'
import PreviewCard from 'components/PreviewCard'

import { GuardianContact } from './Guardian'
import { useMember } from 'components/Providers'
import { useDispatch } from 'react-redux'
import { saveSequences } from 'store/member/contact'
import { useTranslation } from 'react-i18next'

export default function ContactsList({ data }) {
  const dispatch = useDispatch()
  const member = useMember()
  const { t } = useTranslation()
  const guardianContacts = data.filter(item => item.isGuardian === true)
  while (guardianContacts.length < 2) guardianContacts.push({ isGuardian: true })
  const otherContacts = data.filter(item => item.isGuardian !== true)
  let maxSeq = 0
  data.map(item => (maxSeq = Math.max(item.seq, maxSeq)))

  let minSeqOfOtherContacts = 0
  otherContacts.map(item => (minSeqOfOtherContacts = Math.min(item.seq, minSeqOfOtherContacts)))

  const movedItemData = { oldIndex: 0, newIndex: 0, end: false, ongoing: false, id: -1 }

  const setMovedItemData = (oldIndex, newIndex, end, ongoing, id) => {
    if (oldIndex >= 0) {
      movedItemData.oldIndex = oldIndex
      movedItemData.id = id
    }
    if (newIndex >= 0) {
      movedItemData.newIndex = newIndex
    }
    if (!end && ongoing) {
      movedItemData.ongoing = true
      movedItemData.end = false
    }
    if (end && !ongoing) {
      movedItemData.end = true
      movedItemData.ongoing = false
      reOrderFetch()
    }
  }

  const reOrderFetch = () => {
    const temp = []
    otherContacts.forEach(item => {
      if (item.id !== movedItemData.id) {
        temp.push({ id: item.id })
      }
    })
    temp.splice(movedItemData.newIndex, 0, { id: movedItemData.id })

    let newSequences = temp.map((item, index) => {
      item.seq = index + 1 + minSeqOfOtherContacts
      return item
    })

    dispatch(saveSequences({ type: member?.person?.type, memberId: member?.id, newSequences }))

    movedItemData.oldIndex = 0
    movedItemData.newIndex = 0
    movedItemData.end = false
    movedItemData.ongoing = false
    movedItemData.id = -1
  }

  return (
    <Box sx={{ mt: 1 }}>
      <Stack>
        {guardianContacts.map((item, index) => (
          <Box sx={{ mx: 3, my: 2 }} key={index}>
            <GuardianContact
              data={item}
              title={index === 0 ? t('Primary Parent/Guardian') : t('Secondary Parent/Guardian')}
              buttonNavigate={
                item.id
                  ? `/member/${member.person.type}/${member.id}/contacts/${item.id}`
                  : `/member/${member.person.type}/${member.id}/contacts/new?seq=${maxSeq + 1}&guardian=true`
              }
              buttonLabel={item.id ? t('More') : t('Add Guardian')}
            />
          </Box>
        ))}
      </Stack>
      <Box sx={{ m: 3, my: 2 }}>
        <PreviewCard
          title={t('Additional Contacts')}
          size="large"
          buttonLabel={t('Add Contact')}
          buttonNavigate={`/member/${member.person.type}/${member.id}/contacts/new?seq=${maxSeq + 1}`}
          buttonDisabled={otherContacts?.length >= 5 ? true : false}
        >
          <Box sx={{ width: '100%' }} id="otherContactsBox">
            {otherContacts?.length > 0 ? (
              otherContacts?.map((item, index) => (
                <StudentListItem
                  key={item.id}
                  item={item}
                  index={index}
                  totalItem={otherContacts.length}
                  setMovedItemData={setMovedItemData}
                  itemCount={otherContacts.length}
                />
              ))
            ) : (
              <Box sx={{ m: 3 }}>{t('No Records')}</Box>
            )}
          </Box>
        </PreviewCard>
      </Box>
    </Box>
  )
}
